export const root = {
  h1: {
    variant: 'text.display-heading-xl',
    '&:first-of-type': {
      mt: 0,
    },
  },
  h2: {
    variant: 'text.display-heading-l',
    '&:first-of-type': {
      mt: 0,
    },
  },
  h3: {
    variant: 'text.display-heading-m',
    '&:first-of-type': {
      mt: 0,
    },
  },
  h4: {
    variant: 'text.compact-text-xl',
    '&:first-of-type': {
      mt: 0,
    },
  },
  h5: {
    variant: 'text.compact-text-l',
    '&:first-of-type': {
      mt: 0,
    },
  },
  h6: {
    variant: 'text.compact-text-m',
    '&:first-of-type': {
      mt: 0,
    },
  },
  p: {
    variant: 'text.display-paragraph-xl',
    whiteSpace: 'pre-line',
  },
  figcaption: {
    variant: 'text.display-paragraph-m',
    color: 'white-alpha-75',
  },
  blockquote: {
    backgroundColor: 'gray-800',
    variant: 'text.display-paragraph-xl',
    fontStyle: 'italic',
    p: 4,
  },
  pre: {
    fontFamily: 'Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace',
  },
  a: {
    width: 'fit-content',
    height: 'fit-content',
    color: 'pink-500',
    textDecoration: 'none',
  },
  ol: {
    variant: 'text.display-paragraph-xl',
  },
  ul: {
    variant: 'text.display-paragraph-xl',
    li: {
      lineHeight: '2em',
    },
  },
  hr: {
    border: 0,
    borderTop: '1px solid',
    borderColor: 'white-alpha-25',
    mx: 0,
    mt: 5,
    mb: 7,
  },
  table: {
    borderCollapse: 'collapse',
    maxWidth: '100%',
  },
  td: {
    border: '1px solid',
    borderColor: 'gray-800',
    px: 4,
    py: 2,
  },
  th: {
    border: '1px solid',
    borderColor: 'gray-800',
    px: 4,
    py: 2,
  },
  img: {
    maxWidth: '100%',
    objectFit: 'contain',
  },
  section: {},
};
