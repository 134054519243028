import flagUS from '~/public/assets/images/flag-us.jpg';
import { Box, FlexLayout, Icon, Image, useIsMenuOpen } from '~/ui';

import { CountryPickerMenu } from './CountryPickerMenu';

export const CountryPicker = () => {
  const [isMenuOpen, setIsMenuOpen] = useIsMenuOpen();

  return (
    <Box onOutsideClick={() => setIsMenuOpen(false)}>
      <FlexLayout space={3} onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <Image src={flagUS.src} />
        <Icon icon={isMenuOpen ? 'chevronUp' : 'chevronDown'} />
      </FlexLayout>
      {isMenuOpen && <CountryPickerMenu />}
    </Box>
  );
};
