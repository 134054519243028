import { Timeline } from 'react-twitter-widgets';

import { useScreenType } from '~/ui';

interface TwitterTimelineProps {
  username: string | undefined;
}

export const TwitterTimeline: React.FC<TwitterTimelineProps> = ({ username = 'belongArenas' }) => {
  const { isDesktop } = useScreenType();

  return (
    <Timeline
      dataSource={{
        sourceType: 'profile',
        screenName: username,
      }}
      options={{
        theme: 'dark',
        height: isDesktop ? '800' : '640',
        chrome: 'nofooter, noscrollbar',
        dnt: true,
      }}
      renderError={(_err) => <p>Could not load timeline</p>}
    />
  );
};
