import { Box, FlexLayout, Icon, Only, Text, theme, useIsMenuOpen } from '~/ui';

import { CountryPickerMenu } from './CountryPickerMenu';
import { MobileMenuFooterList } from './MobileMenuFooterList';
import { NavbarList } from './NavbarList';

export const MobileMenu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useIsMenuOpen();

  return (
    <Only for="mobile">
      <Box onOutsideClick={() => setIsMenuOpen(false)}>
        <Icon icon={isMenuOpen ? 'close' : 'menu'} onClick={() => setIsMenuOpen(!isMenuOpen)} />
        {isMenuOpen && (
          <FlexLayout
            bg="black"
            flexDirection="column"
            px={5}
            py={4}
            space={7}
            sx={{
              border: `1px solid ${theme.colors['gray-800']}`,
              bottom: '32px',
              left: '0',
              overflowY: 'auto',
              position: 'fixed',
              right: '32px',
              top: '72px',
              zIndex: 3,
            }}
          >
            <FlexLayout flexDirection="column" space={6}>
              <Text color="white-alpha-50" variant="text-s-medium">
                Belong
              </Text>
              <NavbarList />
            </FlexLayout>
            <MobileMenuFooterList />
            <CountryPickerMenu />
          </FlexLayout>
        )}
      </Box>
    </Only>
  );
};
