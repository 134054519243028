import { useRouter } from 'next/router';
import Script from 'next/script';

import { Footer, Navbar } from '~/components/layout';
import { useAuthenticatedUser } from '~/hooks/api';
import { isEnabledFeature } from '~/services/featureFlags';
import { FlexLayout, ToastMessageContainer } from '~/ui';
import { includes } from '~/utils/string';

import { CenteredLoadingSpinner } from '../shared';

const nonStaticAuthUrls = ['/account', '/my-bookings', '/book'];

export const MainLayout = ({ children }) => {
  const { isLoading } = useAuthenticatedUser();
  const { pathname } = useRouter();
  const displayLoadingSpinner = isLoading && nonStaticAuthUrls.some((url) => includes(pathname, url));

  return (
    <FlexLayout bg="background-dark" flexDirection="column" sx={{ minHeight: '100vh' }}>
      {isEnabledFeature('zendesk') && (
        <Script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=e22ccd0e-12e0-49d4-99cf-86a3c4e16cd4"
        />
      )}
      <Navbar />
      <FlexLayout flexDirection="column" flexGrow={1}>
        {displayLoadingSpinner ? <CenteredLoadingSpinner /> : children}
      </FlexLayout>
      <Footer />
      <ToastMessageContainer />
    </FlexLayout>
  );
};
