import axios, { AxiosRequestConfig } from 'axios';

import { getSegmentAnonymousId, getSegmentUserId } from '~/services/analytics';
import { isEnabledFeature } from '~/services/featureFlags';
import { decorateWithBearerToken } from '~/utils/auth';

import { getAccessToken } from './auth';

function monkeyPatchAxiosStackTrace(axiosInstance) {
  ['post', 'put', 'patch', 'delete', 'get'].forEach((method) => {
    axiosInstance[`original${method}`] = axiosInstance[method];

    axiosInstance[method] = async (...args) => {
      const { stack } = new Error();
      try {
        return await axiosInstance[`original${method}`](...args);
      } catch (error) {
        error.stack = stack;
        throw error;
      }
    };
  });
}

async function authorizeClientSideRequest(config: AxiosRequestConfig) {
  try {
    return decorateWithBearerToken(config, await getAccessToken());
  } catch (e) {
    // no access token, proceeding as unauthorized
    return config;
  }
}

async function addAnonymousIdHeader(config: AxiosRequestConfig) {
  if (!isEnabledFeature('segment-user-id')) {
    return config;
  }

  const segmentAnonymousId = getSegmentAnonymousId();
  if (!config.headers) {
    config.headers = {};
  }

  if (segmentAnonymousId) {
    config.headers['User-AnonymousId'] = segmentAnonymousId;
  }

  const segmentUserId = getSegmentUserId();
  if (segmentUserId) {
    config.headers['User-Id'] = segmentUserId;
  }

  return config;
}

function configureBaseUrl(config: AxiosRequestConfig) {
  config.baseURL = process.env.NEXT_PUBLIC_BACKEND_URL_AUTH0 ?? process.env.NEXT_PUBLIC_BACKEND_URL;

  return config;
}

async function authorizeRequest(config: AxiosRequestConfig) {
  return authorizeClientSideRequest(config);
}

const rethrow = (error) => {
  throw error;
};

type AxiosFunction = <T>(url: string, config?: AxiosRequestConfig) => Promise<T>;
type AxiosDataFunction = <T>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T>;

async function decorateRequest(config: AxiosRequestConfig) {
  return addAnonymousIdHeader(await authorizeRequest(configureBaseUrl(config)));
}

export function backendService() {
  if (!process.env.NEXT_PUBLIC_BACKEND_URL) {
    throw new Error('Missing NEXT_PUBLIC_BACKEND_URL env var');
  }

  const instance = axios.create();
  monkeyPatchAxiosStackTrace(instance);

  instance.interceptors.request.use(decorateRequest, rethrow);
  instance.interceptors.response.use(async (response) => {
    // By doing this we'll get response.data by default, no need to destruct it in each axios call
    const { config, data } = response as any;

    return config.fullResponse ? response : data;
  });

  return {
    get: instance.get as AxiosFunction,
    post: instance.post as AxiosDataFunction,
    delete: instance.delete as AxiosFunction,
    put: instance.put as AxiosDataFunction,
    patch: instance.patch as AxiosDataFunction,
  };
}

export const backend = backendService();
