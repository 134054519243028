import { DateTime } from 'luxon';
import pluralize from 'pluralize';

import { GetArena, GetBooking } from '~/apis';
import { Icon } from '~/ui/theme';
import { insertBeforeLast } from '~/utils/array';
import { IdToken } from '~/utils/auth';
import { formatISODateTime, getDateTimeDiff } from '~/utils/date';
import { getTimeOfDay } from '~/utils/time';
import { isSameUser } from '~/utils/user';

export function getName({ arena, date, timezone = 'utc', isAllDayPass }) {
  const datetime = DateTime.fromISO(date, { zone: timezone });

  if (isAllDayPass) {
    return `${datetime.toFormat('MMMM d')} at ${arena.name}`;
  }

  return `${datetime.toFormat('EEEE')} ${getTimeOfDay(datetime)} at ${arena.name}`;
}

export function getSeatInfo(seatReservation: GetBooking.SeatReservation) {
  const { seatCount, reserved } = seatReservation;
  const seatNumbers = reserved?.map(({ seats }) => seats.map(({ slug }) => slug.replace('-', ''))).flat();

  return {
    seatCount,
    isSingleSeat: seatCount === 1,
    seatNumbers: seatNumbers?.sort((a, b) => a.localeCompare(b, 'en', { numeric: true })),
  };
}

export const getTimeDurationText = (start: string, end: string) => {
  const { hours, minutes } = getDateTimeDiff({ start, end }, ['hours', 'minutes']);

  if (hours && minutes) {
    return `${hours}:${minutes} ${pluralize('hr', hours)}`;
  }

  if (hours && !minutes) {
    return pluralize('hr', hours, true);
  }

  if (!hours && minutes) {
    return `${minutes} mins`;
  }

  return 'Unknown duration';
};

export function getDateInfo(seatReservation: GetBooking.SeatReservation, timezone: string) {
  const { fromTime, toTime } = seatReservation;

  const date = formatISODateTime(fromTime, 'MMMM d', timezone);
  const startTime = formatISODateTime(fromTime, 'h:mm a', timezone).replace(':00', '');
  const endTime = formatISODateTime(toTime, 'h:mm a', timezone).replace(':00', '');

  const duration = getTimeDurationText(fromTime, toTime);
  const timeRange = `${startTime} \u2014 ${endTime} (${duration})`;

  return { date, timeRange };
}

export function getAddress(location: GetArena.Location) {
  const { address, address2, city, postalCode } = location;

  return `${address}, ${address2}, ${postalCode}, ${city}`;
}

export function getPlayersInfo(
  seat: GetBooking.SeatReservation,
  invitedUsers: GetBooking.User[],
  currentUser: IdToken,
) {
  const { seatCount } = seat;
  const { isSingleSeat } = getSeatInfo(seat);
  const users = invitedUsers.filter((user) => !isSameUser(user, currentUser));

  const playersIcon: Icon = isSingleSeat ? 'user' : 'users';
  const playersText = pluralize('player', seatCount, true);
  return {
    playersIcon,
    playersSubtext: isSingleSeat ? '' : getPlayerSubstring(seatCount, users),
    playersText,
  };
}

function getPlayerSubstring(seatCount: number, users: GetBooking.User[]): string {
  return users.length ? 'Playing with ' + getDisplayNamesString(users) : '';
}

function getDisplayNamesString(users: GetBooking.User[]) {
  const acceptedNames = users.filter((user) => user.accepted).map(getDisplayName);
  const pendingNames = users.filter((user) => !user.accepted).map(getDisplayName);

  const names = [...acceptedNames, ...pendingNames];
  const removedCount = names.splice(3).length;

  if (removedCount > 0) {
    names.push(`${removedCount} more`);
  }

  return insertBeforeLast(names);
}

function getDisplayName(user: GetBooking.User): string {
  if (user.accepted) {
    return user.displayName ?? user.email;
  }
  return `${user.email} (Pending)`;
}

export function checkIsMembershipBooking(booking: GetBooking.RootObject) {
  return !!booking.membership?.applied?.length;
}
