import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export function useLockBodyScroll(value: boolean) {
  useEffect(() => {
    document.body.style.overflow = value ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [value]);
}

export function useIsMenuOpen(): [boolean, (status: boolean) => void] {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useRouter();

  useEffect(() => setIsMenuOpen(false), [pathname]);
  useLockBodyScroll(isMenuOpen);

  return [isMenuOpen, setIsMenuOpen];
}
