import { useCountrySelectionChecker } from '~/components/CountryPickerModal/useCountrySelectionChecker';
import flagUK from '~/public/assets/images/flag-uk.jpg';
import flagUS from '~/public/assets/images/flag-us.jpg';
import { Box, Divider, FlexLayout, Only, Text } from '~/ui';

import { CountryPickerMenuItem } from './CountryPickerMenuItem';

export const CountryPickerMenu = () => {
  const { markCountrySelected } = useCountrySelectionChecker();

  return (
    <FlexLayout
      bg="black"
      flexDirection="column"
      px={[0, 5]}
      py={[0, 4]}
      space={4}
      sx={{
        height: '248px',
        maxWidth: '332px',
        position: ['unset', 'fixed'],
        right: 0,
        top: [0, '72px'],
      }}
    >
      <Text color="white-alpha-75" variant="text-s-medium">
        Location
      </Text>
      <Only for="tabletAndDesktop">
        <Box sx={{ flexGrow: 0 }}>
          <Divider />
        </Box>
      </Only>
      <CountryPickerMenuItem backgroundColor="gray-800" image={flagUS.src} mobileWidth="205px" text="United States" />
      <CountryPickerMenuItem
        href="https://belong.gg"
        image={flagUK.src}
        mobileWidth="205px"
        text="United Kingdom"
        onClick={() => markCountrySelected('uk')}
      />
    </FlexLayout>
  );
};
