import { FieldValidator } from 'final-form';
import { useField } from 'react-final-form';

import { Box, Button, FlexLayout, TextInput, useScreenType, withFieldLabels } from '~/ui';

interface EmailAddressFieldProps {
  error: string;
  isDisabled?: boolean;
  label: string;
  value: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onFocus: () => void;
}

const EmailAddressFieldComponent: React.FC<EmailAddressFieldProps> = ({
  value,
  onBlur,
  onChange,
  onFocus,
  onSubmit,
}) => {
  const { isMobile } = useScreenType();

  return (
    <FlexLayout alignItems="center" as="form" flexDirection={['column', 'row']} space={5} onSubmit={onSubmit}>
      <Box sx={{ width: ['100%', '360px'] }}>
        <TextInput
          iconLeft="email"
          placeholder="Type in your email address..."
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
      </Box>
      <Button isFullWidth={isMobile} size="l" text="Next" type="submit" variant="secondary" />
    </FlexLayout>
  );
};

export const EmailAddressField = withFieldLabels(EmailAddressFieldComponent);

interface EmailAddressFormFieldProps {
  name: string;
  label: string;
  validate?: FieldValidator<any>;
  onSubmit: () => void;
}

export const EmailAddressFormField: React.FC<EmailAddressFormFieldProps> = ({ name, label, validate, onSubmit }) => {
  const {
    input: { value, onChange, onBlur, onFocus },
    meta,
  } = useField(name, { validate });

  const error = meta.touched ? meta.error || meta.submitError : undefined;

  return (
    <EmailAddressField
      error={error}
      label={label}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onSubmit={onSubmit}
    />
  );
};
