import { AxiosError } from 'axios';
import throttle from 'lodash/throttle';
import upperFirst from 'lodash/upperFirst';

import { showToast } from '~/ui';

function extractEndpoint(error) {
  const baseURL = error.config?.baseURL || '';
  const method = error.config?.method || '';
  const url = error.config?.url;

  if (!url) {
    return undefined;
  }

  return `${method.toUpperCase()} ${baseURL}${url}`.trim();
}

function extractDetails(error: AxiosError<any>) {
  return error.response?.data?.type;
}

function extractMessage(error) {
  const details = extractDetails(error);
  const message = error?.message;

  if (!details && !message) {
    return 'Unknown error';
  }

  if (!details) {
    return message;
  }

  return `${message} (${details})`;
}

export function extractSpecificErrorMessage(error: AxiosError<any>) {
  return upperFirst(
    error.response?.data?.errors?.[0]?.detail ||
      error.response?.data?.errors?.[0]?.message ||
      error.response?.data?.message,
  );
}

function createErrorMessage(error) {
  const specificErrorMessage = extractSpecificErrorMessage(error);
  if (specificErrorMessage) {
    return specificErrorMessage;
  }

  const errorMessage = extractMessage(error);
  const endpoint = extractEndpoint(error);
  if (!endpoint) {
    return errorMessage;
  }

  return `${errorMessage} ${endpoint}`;
}

export function displayErrorMessage(text: string) {
  showToast({
    text,
    variant: 'error',
    canClose: true,
    autoClose: 7000,
  });
}

const displayErrorMessageThrottled = throttle(displayErrorMessage, 10000);

export function displayError(error) {
  const errorMessage = createErrorMessage(error);
  displayErrorMessageThrottled(errorMessage);
}
