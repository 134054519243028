import { FlexLayout, Icon, Text, theme } from '~/ui';

export const variantsMap = {
  custom: {
    icon: '' as theme.Icon,
    iconColor: '' as theme.Color,
  },
  success: {
    icon: 'checkmark' as theme.Icon,
    iconColor: 'green-500' as theme.Color,
  },
  info: {
    icon: 'info' as theme.Icon,
    iconColor: 'green-500' as theme.Color,
  },
  warning: {
    icon: 'warning' as theme.Icon,
    iconColor: 'yellow-500' as theme.Color,
  },
  error: {
    icon: 'warning' as theme.Icon,
    iconColor: 'red-500' as theme.Color,
  },
};

interface ActionProps {
  text: string;
  onClick: () => void;
}

export interface AlertProps {
  action?: ActionProps;
  icon?: theme.Icon;
  iconColor?: theme.Color;
  text: string;
  variant?: keyof typeof variantsMap;
  onCloseClick?: () => void;
}

export const Alert: React.FC<AlertProps> = ({ action, icon, iconColor, text, variant = 'custom', onCloseClick }) => {
  const variantIcon = variant === 'custom' ? icon : variantsMap[variant]?.icon;
  const variantIconColor = variant === 'custom' ? iconColor : variantsMap[variant]?.iconColor;

  return (
    <FlexLayout alignItems="center" bg="gray-900" px={5} py={4} role="alert" space={5}>
      {variantIcon && variantIconColor && (
        <Icon color={variantIconColor} data-testid="ui/alert__icon" icon={variantIcon} />
      )}
      <FlexLayout flexDirection="column" flexGrow={1} space={2}>
        <Text>{text}</Text>
        {action && (
          <Text color="pink-500" variant="text-m-medium" onClick={action.onClick}>
            {action.text}
          </Text>
        )}
      </FlexLayout>
      {onCloseClick && <Icon data-testid="ui/alert__close-icon" icon="close" onClick={onCloseClick} />}
    </FlexLayout>
  );
};
