import axios from 'axios';
import memoize from 'memoizee';

type FeatureName =
  | 'add-to-calendar-button'
  | 'allow-anonymous-booking-flow'
  | 'careers'
  | 'covid-info'
  | 'download-invoice-button'
  | 'email-notifications'
  | 'events-page'
  | 'membership'
  | 'payment'
  | 'recaptcha'
  | 'segment-user-id'
  | 'sms-notifications'
  | 'sponsors-widget'
  | 'uk-redirect'
  | 'gift-card'
  | 'membership-creation'
  | 'retain-booking-flow-session'
  | 'gift-card-nonce-override'
  | 'new-square-sdk'
  | 'competitions'
  | 'zendesk'
  | 'games-page'
  | 'battlefy-programs'
  | 'arena-pass'
  | 'bm-page'
  | 'maintenance-mode'
  | 'adsense'
  | 'seat-admin'
  | 'booking-delays';

export interface Parameters {
  groupId: string;
  rollout: string;
  stickiness: string;
  userIds: string;
}

export interface Strategy {
  name: string;
  parameters: Parameters;
}

export interface Feature {
  name: string;
  description: string;
  enabled: boolean;
  strategies: Strategy[];
}

export interface ToggledFeaturesRootObject {
  version: number;
  features: Feature[];
}

export async function fetchFeatureFlagsInternal() {
  const baseURL = process.env.UNLEASHED_FEATURE_TOGGLE_URL;
  if (!baseURL) {
    throw new Error('missing UNLEASHED_FEATURE_TOGGLE_URL env variable');
  }

  const instanceId = process.env.UNLEASHED_FEATURE_TOGGLE_INSTANCE_ID;
  if (!instanceId) {
    throw new Error('missing UNLEASHED_FEATURE_TOGGLE_INSTANCE_ID env variable');
  }

  const appName = process.env.UNLEASHED_FEATURE_TOGGLE_APP_NAME;
  if (!appName) {
    throw new Error('missing UNLEASHED_FEATURE_TOGGLE_APP_NAME env variable');
  }

  const url = baseURL + '/client/features';

  const result = await axios.get<ToggledFeaturesRootObject>(url, {
    headers: {
      'UNLEASH-INSTANCEID': instanceId,
      'UNLEASH-APPNAME': appName,
    },
  });

  const features = result.data.features.filter((f) => f.enabled).map((f) => f.name);
  return applyFeatureFlagsOverrides(features);
}

function applyFeatureFlagsOverrides(flags: string[]) {
  const featuresToAdd = process.env.ADD_FEATURE_FLAGS?.split(',') ?? [];
  const featuresToRemove = process.env.REMOVE_FEATURE_FLAGS?.split(',') ?? [];

  return flags
    .filter((flag) => !featuresToRemove.includes(flag)) // filter out features to remove locally
    .concat(featuresToAdd); // include features to add locally
}

let activeFeaturesMap: Record<FeatureName, boolean | undefined> = {} as any;

export function setActiveFeatures(clientFeatures: string[]) {
  activeFeaturesMap = {} as any;
  clientFeatures.forEach((feature) => (activeFeaturesMap[feature] = true));
}

export function getFeatureFlags() {
  return activeFeaturesMap;
}

export function isEnabledFeature(name: FeatureName) {
  return !!activeFeaturesMap[name];
}

const fiveMinutes = 5 * 60 * 1000;

export const fetchFeatureFlags = memoize(fetchFeatureFlagsInternal, {
  maxAge: fiveMinutes,
}) as typeof fetchFeatureFlagsInternal;

export function loadFeatureFlagsFromEnvVar() {
  if (process.env.NEXT_PUBLIC_FEATURE_FLAGS === undefined) {
    throw new Error('Missing NEXT_PUBLIC_FEATURE_FLAGS env var');
  }

  const flags = applyFeatureFlagsOverrides(process.env.NEXT_PUBLIC_FEATURE_FLAGS.split(','));

  setActiveFeatures(flags);
}
