import { Box, theme } from '~/ui';

interface DividerProps {
  color?: theme.Color;
  height?: string;
  width?: string;
  orientation?: 'horizontal' | 'vertical';
}

export const Divider: React.FC<DividerProps> = ({
  color = 'white-alpha-25',
  height,
  orientation = 'horizontal',
  width,
}) => {
  const isHorizontal = orientation === 'horizontal';
  return (
    <Box
      bg={color}
      sx={{
        flexGrow: isHorizontal && !width ? 1 : 0,
        flexShrink: 0,
        height: isHorizontal ? '1px' : height ?? 'auto',
        width: isHorizontal ? width : '1px',
      }}
    />
  );
};
