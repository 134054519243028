import { Router, useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { getQueryStringParam } from '~/utils/routing';

export function useRouterPush() {
  const { push } = useRouter();

  const [{ loading, value, error }, wrappedPush] = useAsyncFn(
    async (...args: Parameters<Router['push']>) => {
      return await push(...args);
    },
    [push],
  );

  return { loading, success: value, error, push: wrappedPush };
}

export function useSubRoute<T>(index: number) {
  const { query } = useRouter();
  const { subroutes } = query;

  if (!subroutes) {
    return { selection: undefined };
  }

  if (!Array.isArray(subroutes)) {
    throw new Error('Sub routes must be an array');
  }

  const selection = subroutes[index] as unknown as T;

  return { selection };
}

export function useStringQueryParam(name: string): string {
  const router = useRouter();
  return getQueryStringParam(router, name);
}

export function useQueryParamState(name: string): [string | undefined, (value: string | undefined) => Promise<void>] {
  const { query, replace, pathname } = useRouter();
  const queryValue = query[name];
  const value = typeof queryValue === 'string' ? queryValue : undefined;

  async function setValue(value: string | undefined) {
    const newQuery = { ...query };
    if (value === undefined) {
      delete newQuery[name];
    } else {
      newQuery[name] = value;
    }
    await replace({ pathname, query: newQuery }, undefined, { shallow: true });
  }

  return [value, setValue];
}

export function useCurrentUrl() {
  const [currentUrl, setCurrentUrl] = useState<string | undefined>();
  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  return currentUrl;
}
