import '~/styles/add-to-calendar-override.css';
import '~/styles/article.css';
import '~/styles/globals.css';
import '~/ui/components/Datepicker/react-calendar-override.css';

import { appWithTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';
import { ThemeProvider } from 'theme-ui';

import { Auth0Provider } from '~/components/Auth0Provider';
import { ClientSideOnly } from '~/components/ClientSideOnly';
import { CountryPickerModal } from '~/components/CountryPickerModal';
import { useCountryRedirection } from '~/components/CountryPickerModal/useCountrySelectionChecker';
import { Head } from '~/components/Head';
import { MainLayout } from '~/components/layout';
import { MaintenancePage } from '~/components/MaintenancePage';
import { QueryClientCreator } from '~/components/QueryClientCreator';
import { ContentStackGraphqlProvider } from '~/hooks/ContentStackGraphqlProvider';
import { StatefulRouter } from '~/hooks/statefulRouter';
import { setupEventListeners, useSegmentAnonymousId } from '~/services/analytics';
import { loadFeatureFlagsFromEnvVar } from '~/services/featureFlags';
import { mergeDehydratedState } from '~/services/hydrate';
import { theme } from '~/ui';

loadFeatureFlagsFromEnvVar();

function App({ Component, pageProps }) {
  useEffect(setupEventListeners);
  useSegmentAnonymousId();
  useCountryRedirection();
  const dehydratedState = mergeDehydratedState(pageProps.dehydratedState);

  return (
    <ContentStackGraphqlProvider>
      <Auth0Provider>
        <StatefulRouter>
          <QueryClientCreator>
            <Hydrate state={dehydratedState}>
              <CookiesProvider>
                <ThemeProvider theme={theme}>
                  <Head />
                  <MaintenancePage>
                    <MainLayout>
                      <ClientSideOnly>
                        <CountryPickerModal />
                      </ClientSideOnly>
                      <Component {...pageProps} />
                    </MainLayout>
                  </MaintenancePage>
                </ThemeProvider>
              </CookiesProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </Hydrate>
          </QueryClientCreator>
        </StatefulRouter>
      </Auth0Provider>
    </ContentStackGraphqlProvider>
  );
}

export default appWithTranslation(App);
