import axios from 'axios';
import { Region, Stack } from 'contentstack';

import { GetContentTypes } from '~/apis';
import { getContentStackConfig } from '~/utils/environment';
import { dropLeadingSlash } from '~/utils/string';

const { apiKey, deliveryToken, envName } = getContentStackConfig();

const client = Stack({
  api_key: apiKey,
  delivery_token: deliveryToken,
  environment: envName,
  region: Region.US,
});

export async function fetchContentStackData<T>(contentId: string) {
  return (await client.ContentType(contentId).Query().addQuery('include_schema', 'true').toJSON().find()) as T;
}

export async function fetchContentStackItem<T>(contentId: string, key: string, value: string) {
  const query = client.ContentType(contentId).Query().toJSON();
  return (await query.where(key, value).findOne()) as T;
}

export async function fetchContentStackDataWithRefs<T>(contentId: string, ...referenceIds: string[]) {
  return (await client
    .ContentType(contentId)
    .Query()
    .addQuery('include_schema', 'true')
    .includeReference(referenceIds)
    .toJSON()
    .find()) as T;
}

type OrderType = 'ascending' | 'descending';

export async function fetchSortedContentStackData<T>(
  contentId: string,
  sortBy: string,
  order: OrderType = 'ascending',
) {
  return (await client.ContentType(contentId).Query().toJSON()[order](sortBy).find()) as T;
}

export async function fetchEntries(contentId: string, searchOptions: Record<string, any>) {
  let query = client.ContentType(contentId).Query();
  for (const [key, value] of Object.entries(searchOptions)) {
    query = query.where(key, value);
  }

  return await query.toJSON().find();
}

export async function fetchEntriesWithRefs(
  contentId: string,
  searchOptions: Record<string, any>,
  ...referenceIds: string[]
) {
  let query = client.ContentType(contentId).Query();
  for (const referenceId of referenceIds) {
    query = query.includeReference(referenceId);
  }
  for (const [key, value] of Object.entries(searchOptions)) {
    query = query.where(key, value);
  }

  return await query.toJSON().find();
}

const contentStackAxios = axios.create({
  baseURL: 'https://cdn.contentstack.io/v3/',
  headers: {
    api_key: apiKey,
    access_token: deliveryToken,
  },
});

export async function fetchContentTypes() {
  const { data } = await contentStackAxios.get<GetContentTypes.RootObject>('content_types');
  if (!data) {
    throw new Error('Missing data from content stack');
  }

  return data;
}

export async function fetchPagesUrls() {
  const contentTypesUids = (await fetchContentTypes()).content_types
    .filter((type) => type.schema.find((field) => field.uid === 'url'))
    .map((type) => type.uid);

  const pagesUrls = await Promise.all(
    contentTypesUids.map(async (uid) => {
      const data = await fetchContentStackData<any[][]>(uid);
      const { url, dynamic } = data[0][0] ?? {};
      return { contentType: uid, url: url && dropLeadingSlash(url), dynamic, data };
    }),
  );

  // note: only pages with `dynamic = true` field are automatically added to the app
  return pagesUrls.filter(({ url, dynamic }) => dynamic && url);
}
