import { Link } from '~/components/shared';
import { FlexLayout, Image } from '~/ui';

export const StoreAppBadges = () => {
  return (
    <FlexLayout space={4}>
      <Link href="https://apps.apple.com/us/app/belong-gaming/id1564338925" target="_blank">
        <Image src="/assets/images/app-store.svg" sx={{ maxWidth: '144px', height: '40px' }} />
      </Link>
      <Link href="https://play.google.com/store/apps/details?id=gg.belong.android" target="_blank">
        <Image src="/assets/images/play-store.svg" sx={{ maxWidth: '161px', height: '40px' }} />
      </Link>
    </FlexLayout>
  );
};
