import { fonts, fontStyles, fontWeights } from '../fonts';

export default {
  'display-heading-xl': {
    fontFamily: fonts.compact,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.normal,
    fontSize: '96px',
    lineHeight: '104px',
    textTransform: 'uppercase',
  },
  'display-heading-l': {
    fontFamily: fonts.compact,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.normal,
    fontSize: '56px',
    lineHeight: '64px',
    textTransform: 'uppercase',
  },
  'display-heading-m': {
    fontFamily: fonts.compact,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.normal,
    fontSize: '36px',
    lineHeight: '48px',
    textTransform: 'uppercase',
  },
  'display-paragraph-xxl': {
    fontFamily: fonts.body,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.normal,
    fontSize: '24px',
    lineHeight: '40px',
    textTransform: 'none',
  },
  'display-paragraph-xxl-medium': {
    fontFamily: fonts.body,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.medium,
    fontSize: '24px',
    lineHeight: '40px',
    textTransform: 'none',
  },
  'display-paragraph-xxl-bold': {
    fontFamily: fonts.body,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.bold,
    fontSize: '24px',
    lineHeight: '40px',
    textTransform: 'none',
  },
  'display-paragraph-xl': {
    fontFamily: fonts.body,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.normal,
    fontSize: '21px',
    lineHeight: '32px',
    textTransform: 'none',
  },
  'display-paragraph-xl-medium': {
    fontFamily: fonts.body,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.medium,
    fontSize: '21px',
    lineHeight: '32px',
    textTransform: 'none',
  },
  'display-paragraph-xl-bold': {
    fontFamily: fonts.body,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.bold,
    fontSize: '21px',
    lineHeight: '32px',
    textTransform: 'none',
  },
  'display-paragraph-l': {
    fontFamily: fonts.body,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.normal,
    fontSize: '18px',
    lineHeight: '32px',
    textTransform: 'none',
  },
  'display-paragraph-l-medium': {
    fontFamily: fonts.body,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.medium,
    fontSize: '18px',
    lineHeight: '32px',
    textTransform: 'none',
  },
  'display-paragraph-l-bold': {
    fontFamily: fonts.body,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.bold,
    fontSize: '18px',
    lineHeight: '32px',
    textTransform: 'none',
  },
  'display-paragraph-m': {
    fontFamily: fonts.body,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.normal,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'none',
  },
  'display-paragraph-m-medium': {
    fontFamily: fonts.body,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.medium,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'none',
  },
  'display-paragraph-m-bold': {
    fontFamily: fonts.body,
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.bold,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'none',
  },
};
