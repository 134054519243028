import { Box, FlexLayout, Text } from '~/ui';

export interface RadioProps {
  isDisabled?: boolean;
  label?: string;
  value: boolean | null;
  onChange: (value: boolean) => void;
  tabIndex?: number | undefined;
}

export const Radio: React.FC<RadioProps> = ({ isDisabled = false, label, value, onChange, tabIndex }) => {
  function handleToggle() {
    onChange(!value);
  }

  return (
    <FlexLayout alignItems="center" isDisabled={isDisabled} space={4} onClick={handleToggle}>
      <FlexLayout
        alignItems="center"
        aria-checked={value ? 'true' : 'false'}
        flexShrink={0}
        justifyContent="center"
        role="radio"
        sx={{
          bg: value ? 'pink-500' : 'gray-800',
          borderRadius: '12px',
          height: '24px',
          width: '24px',
          '&:hover': {
            bg: value ? 'pink-600' : 'gray-700',
          },
        }}
        tabIndex={tabIndex || 0}
        onKeyPress={(event) => {
          const { key } = event;
          if (key === 'Enter' || key === ' ') {
            handleToggle();
          }
        }}
      >
        {value && <Box sx={{ backgroundColor: 'white', width: '12px', height: '12px', borderRadius: '6px' }} />}
      </FlexLayout>
      {label && <Text variant="text-l">{label}</Text>}
    </FlexLayout>
  );
};
