import { useFooterLinks } from '~/components/layout/Navbar/hooks';
import { Link } from '~/components/shared';
import { FlexLayout, Icon, Image, theme } from '~/ui';

const hoverStyles = {
  transition: 'color .12s ease',
  '&:hover': { color: 'pink-600' },
  '&:active': { color: 'pink-700' },
};

export const SocialsContainer = ({ isBelongChevronIncluded = true }) => {
  const footerLinks = useFooterLinks();

  if (!footerLinks) {
    return null;
  }

  const socialLinks = footerLinks.socialNetworks.map((item) => item.path);
  return (
    <FlexLayout alignItems="center" space={[4, 5]}>
      {isBelongChevronIncluded && <Image src="/assets/images/belong-chevron-m.svg" sx={{ flexShrink: 0 }} />}
      {socialLinks.map(({ title, href }) => {
        const icon = title.toLowerCase();
        return (
          <Link href={href} key={title} target="_blank">
            <Icon icon={icon as theme.Icon} sx={{ ...hoverStyles }} />
          </Link>
        );
      })}
    </FlexLayout>
  );
};
