import { QueryClient } from 'react-query';
import { dehydrate, DehydratedState } from 'react-query/hydration';

function setContentStackQueryData(): DehydratedState {
  if (!process.env.NEXT_PUBLIC_CONTENT_STACK_DATA) {
    return dehydrate(new QueryClient());
  }

  const contentStackData: Array<{ contentId: string; data: any }> = JSON.parse(
    process.env.NEXT_PUBLIC_CONTENT_STACK_DATA,
  );

  const queryClient = new QueryClient();
  contentStackData.forEach(({ contentId, data }) => queryClient.setQueryData([`contentStack:${contentId}`, {}], data));

  return dehydrate(queryClient);
}

const dehydratedContentStack = setContentStackQueryData();

export function mergeDehydratedState(dehydratedPageState: DehydratedState | undefined): DehydratedState {
  const mergedState: DehydratedState = {
    mutations: [],
    queries: [],
  };

  if (!dehydratedPageState) {
    return dehydratedContentStack;
  }

  const state = [dehydratedPageState, dehydratedContentStack] as const;
  state.forEach((s) => {
    mergedState.mutations = [...mergedState.mutations, ...s.mutations];
    mergedState.queries = [...mergedState.queries, ...s.queries];
  });

  return mergedState;
}
