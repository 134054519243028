interface ReportErrorOptions {
  logOnly?: boolean;
  message?: string;
}

export function reportError(error, options: ReportErrorOptions = { logOnly: false }) {
  const { message, logOnly } = options;

  if (message) {
    console.log(message, error);
  } else {
    console.log(error);
  }

  if (!logOnly) {
  }
}
