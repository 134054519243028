/* eslint-disable prettier/prettier */
export const palette = {
  black: '#000000',
  white: '#FFFFFF',
  transparent: 'transparent',
  'background-dark': '#151515',
  'gray-100': '#F8F8F8',
  'gray-200': '#E0E0E0',
  'gray-300': '#C0C0C0',
  'gray-400': '#A0A0A0',
  'gray-500': '#8A8A8A',
  'gray-600': '#606060',
  'gray-700': '#484848',
  'gray-800': '#2A2A2A',
  'gray-900': '#101010',
  'pink-500': '#CA3A95',
  'pink-600': '#BD2C82',
  'pink-700': '#AB1866',
  'green-500': '#21BD13',
  'yellow-500': '#FFA800',
  'red-500': '#FF2E47',
  'green-400': '#9DC73B',
};

export const alphas = {
  'black-alpha-10': 'rgba(0, 0, 0, 0.1)',
  'black-alpha-25': 'rgba(0, 0, 0, 0.25)',
  'black-alpha-50': 'rgba(0, 0, 0, 0.5)',
  'black-alpha-60': 'rgba(0, 0, 0, 0.6)',
  'black-alpha-75': 'rgba(0, 0, 0, 0.75)',
  'white-alpha-10': 'rgba(255, 255, 255, 0.1)',
  'white-alpha-25': 'rgba(255, 255, 255, 0.25)',
  'white-alpha-50': 'rgba(255, 255, 255, 0.5)',
  'white-alpha-60': 'rgba(255, 255, 255, 0.6)',
  'white-alpha-75': 'rgba(255, 255, 255, 0.75)',
};
