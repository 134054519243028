import { AnchorButton, AnchorLinkButton } from '~/components/input';
import { Link } from '~/components/shared';
import { useAuthenticatedUser } from '~/hooks/api';
import { FlexLayout, Image, LoadingSpinner, Only, useScreenType } from '~/ui';

import { AccountMenu } from './AccountMenu';
import { CountryPicker } from './CountryPicker';
import { useBookingsCount } from './hooks';
import { MobileMenu } from './MobileMenu';
import { NavbarItem } from './NavbarItem';
import { NavbarList } from './NavbarList';

export const Navbar: React.FC = () => {
  const { user, isLoading } = useAuthenticatedUser();
  const { isDesktop } = useScreenType();
  const bookingsCount = useBookingsCount();

  const renderUserActions = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (user) {
      return (
        <FlexLayout alignItems="center" flexShrink={0} space={6}>
          <Only for="desktop">
            <NavbarItem href="/account/bookings" pillText={bookingsCount} text="Bookings" />
          </Only>
          <AccountMenu />
        </FlexLayout>
      );
    }

    return (
      <FlexLayout alignItems="center" flexShrink={0} space={[6, 4, 6]}>
        <AnchorLinkButton data-testid="login-button" href="/login" text="Log in" variant="secondary" />
        <AnchorButton data-testid="signup-button" href="/signup" text="Sign up" variant="secondary" />
      </FlexLayout>
    );
  };

  return (
    <FlexLayout
      alignItems="center"
      as="nav"
      bg="black"
      justifyContent="space-between"
      px={4}
      space={[6, 5, 6]}
      sx={{ height: 'navbar-height', position: 'sticky', top: 0, zIndex: 4 }}
    >
      <FlexLayout alignItems="center" space={[6, 5, 6]}>
        <FlexLayout alignItems="center" flexDirection="row" flexShrink={0}>
          <MobileMenu />
          <Link href="/">
            <Image src={isDesktop ? '/assets/images/belong-logo.svg' : '/assets/images/belong-chevron-l.svg'} />
          </Link>
        </FlexLayout>
        <Only for="tabletAndDesktop">
          <NavbarList />
        </Only>
      </FlexLayout>
      <FlexLayout alignItems="center" space={[6, 4, 6]}>
        {renderUserActions()}
        <Only for="tabletAndDesktop">
          <CountryPicker />
        </Only>
      </FlexLayout>
    </FlexLayout>
  );
};
