import { fonts, fontStyles, fontWeights } from '../fonts';

export default {
  'text-xl': {
    fontFamily: fonts.body,
    fontSize: '21px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.normal,
    lineHeight: '32px',
    textTransform: 'none',
  },
  'text-xl-medium': {
    fontFamily: fonts.body,
    fontSize: '21px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.medium,
    lineHeight: '32px',
    textTransform: 'none',
  },
  'text-xl-bold': {
    fontFamily: fonts.body,
    fontSize: '21px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.bold,
    lineHeight: '32px',
    textTransform: 'none',
  },
  'text-l': {
    fontFamily: fonts.body,
    fontSize: '18px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.normal,
    lineHeight: '24px',
    textTransform: 'none',
  },
  'text-l-medium': {
    fontFamily: fonts.body,
    fontSize: '18px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.medium,
    lineHeight: '24px',
    textTransform: 'none',
  },
  'text-l-bold': {
    fontFamily: fonts.body,
    fontSize: '18px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.bold,
    lineHeight: '24px',
    textTransform: 'none',
  },
  'text-m': {
    fontFamily: fonts.body,
    fontSize: '16px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.normal,
    lineHeight: '24px',
    textTransform: 'none',
  },
  'text-m-medium': {
    fontFamily: fonts.body,
    fontSize: '16px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.medium,
    lineHeight: '24px',
    textTransform: 'none',
  },
  'text-m-bold': {
    fontFamily: fonts.body,
    fontSize: '16px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.bold,
    lineHeight: '24px',
    textTransform: 'none',
  },
  'text-s': {
    fontFamily: fonts.body,
    fontSize: '14px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.normal,
    lineHeight: '16px',
    textTransform: 'none',
  },
  'text-s-medium': {
    fontFamily: fonts.body,
    fontSize: '14px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.medium,
    lineHeight: '16px',
    textTransform: 'none',
  },
  'compact-text-xl': {
    fontFamily: fonts.compact,
    fontSize: '24px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.medium,
    lineHeight: '32px',
    textTransform: 'uppercase',
  },
  'compact-text-l': {
    fontFamily: fonts.compact,
    fontSize: '21px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.medium,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  'compact-text-m': {
    fontFamily: fonts.compact,
    fontSize: '16px',
    fontStyle: fontStyles.normal,
    fontWeight: fontWeights.medium,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
};
