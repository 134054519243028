import { FetchNewsItems, GetFeaturedNewsItems } from '~/apis/contentStack';
import { AnchorLinkButton } from '~/components/input';
import { Link } from '~/components/shared';
import { FlexLayout, Image, Text } from '~/ui';
import { formatISODateTime, formatISODateTimeOrdinal } from '~/utils/date';

interface NewsItemCardProps {
  entry: FetchNewsItems.Entry | GetFeaturedNewsItems.NewsItem;
}

export const NewsItemCard: React.FC<NewsItemCardProps> = ({ entry }) => {
  const { cover_image, title, description, publish_date, url } = entry;

  const itemHref = `/news${url}`;

  const formattedDate =
    publish_date &&
    formatISODateTimeOrdinal(formatISODateTime(publish_date, 'yyyy-LL-dd', 'America/New_York'), 'MMMM do');

  return (
    <FlexLayout bg="black" flexDirection="column">
      <Link href={itemHref} sx={{ width: '100%' }}>
        {cover_image && (
          <Image src={cover_image.url} sx={{ height: ['175px', '234px'], objectFit: 'cover', width: '100%' }} />
        )}
      </Link>
      <FlexLayout flexDirection="column" flexGrow={1} pb={5} pt={2} px={5} space={5}>
        <FlexLayout flexDirection="column" flexGrow={1} space={2}>
          <Link href={itemHref}>
            <Text sx={{ wordBreak: 'break-word' }} variant="title-m">
              {title}
            </Text>
          </Link>
          <Text color="white-alpha-75" variant="text-m">
            {formattedDate ?? '-'}
          </Text>
          <Text sx={{ wordBreak: 'break-word' }} variant="text-m">
            {description}
          </Text>
        </FlexLayout>
        <AnchorLinkButton href={itemHref} iconRight="chevronRight" text="Read More" />
      </FlexLayout>
    </FlexLayout>
  );
};
