import teamCelebration from '~/public/assets/images/team-celebration.jpg';
import { Box, FlexLayout, LinkButton, Only, Text, useScreenType } from '~/ui';

interface FormBoxHeaderProps {
  title: string;
  onLeftAction?(): void;
}

const FormBoxHeader: React.FC<FormBoxHeaderProps> = ({ title, onLeftAction }) => {
  const { isMobile } = useScreenType();

  if (!onLeftAction || isMobile) {
    return (
      <FlexLayout justifyContent="center">
        <Text variant="title-l">{title}</Text>
      </FlexLayout>
    );
  }

  return (
    <FlexLayout justifyContent="flex-start" sx={{ position: 'relative' }}>
      <Only for="tabletAndDesktop">
        <LinkButton iconLeft="arrowLeft" text="Back" variant="secondary" onClick={onLeftAction} />
      </Only>
      <Text
        sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
        variant="title-l"
      >
        {title}
      </Text>
    </FlexLayout>
  );
};

export interface FormBoxProps {
  title: string;
  onLeftAction?(): void;
  children?: any;
  onSubmit?(e: any): void;
  'data-testid'?: string;
}

export const FormBox: React.FC<FormBoxProps> = ({
  children,
  title,
  onLeftAction = undefined as any,
  onSubmit,
  'data-testid': dataTestId,
}) => {
  return (
    <Box
      as={onSubmit ? 'form' : undefined}
      data-testid={dataTestId}
      sx={{
        backgroundAttachment: 'fixed',
        backgroundImage: `url(${teamCelebration.src})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flexGrow: 1,
      }}
      onSubmit={onSubmit}
    >
      <FlexLayout bg="transparent" justifyContent="center" pb={10} pt={8}>
        <FlexLayout bg="black" flexDirection="column" p={[4, 5, 5]} space={6} sx={{ width: ['343px', '640px'] }}>
          <FormBoxHeader title={title} onLeftAction={onLeftAction} />
          {children}
        </FlexLayout>
      </FlexLayout>
    </Box>
  );
};
