import { ParallelLimiter } from 'parallel-limiter';

import { CreateBooking, CreateBookingData } from '~/apis';
import { backend } from '~/services/backendService';
import { tryCatch } from '~/utils/async';
import { errorMatches } from '~/utils/error-formats';

const limiter = new ParallelLimiter({ maxParallel: 1 });

let lastBookingId: string | undefined;
export async function createBooking(data: CreateBookingData) {
  if (lastBookingId) {
    await abandonCurrentBooking();
  }

  const [res, error] = await tryCatch(() => backend.post<CreateBooking.RootObject>('/bookings', data));
  let res2;
  // one more try...
  if (error && errorMatches(error as any, 'SEAT_RESERVATIONS_ERROR')) {
    await abandonCurrentBooking();
    res2 = await backend.post<CreateBooking.RootObject>('/bookings', data);
  } else if (error) {
    throw error;
  }
  lastBookingId = res?.booking?.id ?? res2?.booking?.id;
  return res;
}

// sequentialize attempts of parallel executions
export const createBookingLimited = (data) => limiter.schedule(() => createBooking(data));

export async function abandonCurrentBooking() {
  if (!lastBookingId) {
    return;
  }

  try {
    await backend.post(`/bookings/${lastBookingId}/abandon`);
    lastBookingId = undefined;
  } catch (e) {
    console.error(e);
    lastBookingId = undefined;
  }
}
