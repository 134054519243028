export const isServer = () => typeof window === 'undefined';

const imageAssetsRootUrl = process.env.NEXT_PUBLIC_IMAGE_ASSETS_ROOT;
if (!imageAssetsRootUrl) {
  throw new Error('Missing NEXT_PUBLIC_IMAGE_ASSETS_ROOT env variable');
}

export const imageUrl = (relativeUrl: string) => {
  return `${imageAssetsRootUrl}/${relativeUrl}`;
};

const contentStackConfig = process.env.NEXT_PUBLIC_CONTENT_STACK_CONFIG;
if (!contentStackConfig) {
  throw new Error('Missing env var CONTENT_STACK_CONFIG');
}

const [apiKey, deliveryToken, envName] = contentStackConfig.split('|');
if (!apiKey || !deliveryToken || !envName) {
  throw new Error('CONTENT_STACK_CONFIG must contain api-key|delivery-token|env-name');
}

export function getContentStackConfig() {
  return { apiKey, deliveryToken, envName };
}
