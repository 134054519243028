import { Box } from '~/ui';
import { Color } from '~/ui/theme';

interface DotProps {
  color?: Color;
  size?: string;
}

export const Dot: React.FC<DotProps> = ({ color = 'white-alpha-75', size = '6px' }) => {
  return (
    <Box
      sx={{
        backgroundColor: color,
        borderRadius: '50%',
        height: size,
        width: size,
      }}
    />
  );
};
