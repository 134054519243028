import { FlexLayout, GridLayout, Image, Text } from '~/ui';

interface SponsorsContainerProps {
  data?: {
    title: string;
    logos: any[];
    active: boolean;
  };
}

export const SponsorsContainer = ({ data }: SponsorsContainerProps) => {
  if (!data) {
    return null;
  }

  if (!data.active) {
    return null;
  }

  const { title, logos } = data;
  return (
    <FlexLayout bg="black" flexDirection="column" px={[4, 7]} py={7} space={6}>
      <Text sx={{ textAlign: 'center' }} variant="display-heading-m">
        {title}
      </Text>
      <GridLayout
        alignItems="center"
        gap={5}
        gridTemplateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
        justifyItems="center"
      >
        {logos.map((logo) => (
          <Image key={logo.uid} src={logo.url} />
        ))}
      </GridLayout>
    </FlexLayout>
  );
};
