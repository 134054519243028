import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

export function clearNonDigits(value: string) {
  return value.replace(/\D+/g, '');
}

// pulled from https://stackoverflow.com/a/7616484/3581420
export function hashString(str: string) {
  let hash = 0;
  let i;
  let chr;

  if (str.length === 0) {
    return hash.toString();
  }
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString();
}

export function capitalizeWordsWithChar(value: string, char = ' ') {
  return startCase(camelCase(value)).replace(/ /g, char);
}

export function dropLeadingSlash(value: string) {
  return value.startsWith('/') ? value.substring(1) : value;
}

export function includes(value: string, characters: string, { caseSensitive = false, trim = true } = {}) {
  if (!caseSensitive) {
    value = value.toLowerCase();
    characters = characters.toLowerCase();
  }

  if (trim) {
    value = value.trim();
    characters = characters.trim();
  }

  return value.includes(characters);
}
