import { Auth0Provider as DefaultAuth0Provider, useAuth0 } from '@auth0/auth0-react';

let accessTokenGetter = async () => '';

export async function getAuth0AccessToken() {
  return accessTokenGetter();
}

function AccessTokenExtractor({ children }) {
  const { getAccessTokenSilently } = useAuth0();
  accessTokenGetter = () => getAccessTokenSilently();

  return children;
}

const [domain, audience, clientId, redirectUri, registerApiUrl, updateUserApiUrl] = (
  process.env.NEXT_PUBLIC_AUTH0_CONFIG ?? ''
).split('|');

export const auth0EnvValues = {
  domain,
  audience,
  clientId,
  redirectUri,
  registerApiUrl,
  updateUserApiUrl,
};

export function Auth0Provider({ children }) {
  if ([domain, audience, clientId, redirectUri].some((x) => !x)) {
    throw new Error('Invalid or missing Auth0 env var');
  }

  return (
    <DefaultAuth0Provider
      audience={audience}
      clientId={clientId}
      domain={domain}
      redirectUri={redirectUri}
      scope="read:current_user update:current_user_metadata"
      useRefreshTokens
    >
      <AccessTokenExtractor>{children}</AccessTokenExtractor>
    </DefaultAuth0Provider>
  );
}
