import { Datepicker, SingleSelect, Textarea, TextInput } from '../components';
import * as form from './form';

export type DatepickerWithFieldLabelsProps = React.ComponentProps<typeof DatepickerWithFieldLabels>;
export type SingleSelectWithFieldLabelsProps = React.ComponentProps<typeof SingleSelectWithFieldLabels>;
export type TextInputWithFieldLabelsProps = React.ComponentProps<typeof TextInputWithFieldLabels>;

export const DatepickerWithFieldLabels = form.withFieldLabels(Datepicker);
export const SingleSelectWithFieldLabels = form.withFieldLabels(SingleSelect);
export const TextInputWithFieldLabels = form.withFieldLabels(TextInput);
export const TextareaWithFieldLabels = form.withFieldLabels(Textarea);
