import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import { useEffect, useState } from 'react';
import Calendar, { CalendarTileProperties } from 'react-calendar';

import { Box, FlexLayout, Icon, Text, theme } from '~/ui';

function getDisabledDays({
  date,
  disabledDays,
}: {
  date: CalendarTileProperties['date'];
  disabledDays: number[] | undefined;
}) {
  if (disabledDays) {
    return disabledDays.some((day) => day === date.getDay());
  }

  return false;
}

function checkIfTileDisabled({ date }: CalendarTileProperties, disabledDays: number[] | undefined) {
  return date < startOfDay(new Date()) || getDisabledDays({ date, disabledDays });
}

function renderTileContent({ date }: CalendarTileProperties) {
  return <Text>{date.getDate()}</Text>;
}

type DatepickerValue = Date | [Date, Date];

interface DatepickerProps {
  allowPastDate?: boolean;
  format?: string;
  iconLeft?: theme.Icon;
  isDisabled?: boolean;
  placeholder?: string;
  value: DatepickerValue | undefined;
  onChange(date: DatepickerValue): void;
  minDate?: Date;
  'data-testid'?: string;
  isRange?: boolean;
  disabledDays?: number[];
}

export const Datepicker: React.FC<DatepickerProps> = ({
  allowPastDate = false,
  iconLeft,
  isDisabled = false,
  isRange = false,
  placeholder = 'Select date',
  value,
  minDate,
  onChange,
  format: dateFormat = 'd LLL Y',
  'data-testid': dataTestId,
  disabledDays,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  function formatDateLabel() {
    if (!value) {
      return placeholder;
    }

    if (Array.isArray(value)) {
      if (!value.length) {
        return placeholder;
      }

      return `${format(value[0], 'd LLL')} \u2014 ${format(value[1], 'd LLL')}`;
    }

    return format(value, dateFormat);
  }

  useEffect(() => {
    if (!minDate || !value) {
      return;
    }

    if (minDate > value) {
      onChange(minDate);
    }
  }, [minDate]);

  return (
    <Box sx={{ position: 'relative' }} onOutsideClick={() => isOpen && setIsOpen(false)}>
      <FlexLayout
        alignItems="center"
        bg={isOpen ? 'gray-600' : 'gray-800'}
        data-testid={dataTestId}
        isDisabled={isDisabled}
        p={4}
        space={3}
        sx={{
          borderRadius: 's',
          height: 'input-l-height',
          position: 'relative',
          '&:hover': {
            backgroundColor: isOpen ? 'gray-600' : 'gray-700',
          },
        }}
        onClick={() => setIsOpen((open) => !open)}
      >
        {iconLeft && <Icon icon={iconLeft} />}
        <Text color={value ? 'white' : 'white-alpha-50'} sx={{ flexGrow: 1 }}>
          {formatDateLabel()}
        </Text>
        <Icon icon={isOpen ? 'chevronUp' : 'chevronDown'} />
      </FlexLayout>
      {isOpen && (
        <Box sx={{ position: 'absolute', top: theme.sizes['input-l-height'], zIndex: 5 }}>
          <Calendar
            locale="en-US"
            minDate={minDate}
            next2Label={null}
            nextLabel={<Icon color="white" icon="chevronRight" />}
            prev2Label={null}
            prevLabel={<Icon color="white" icon="chevronLeft" />}
            selectRange={isRange}
            tileContent={renderTileContent}
            tileDisabled={allowPastDate ? undefined : (props) => checkIfTileDisabled(props, disabledDays)}
            value={value}
            view="month"
            onChange={(date: DatepickerValue) => {
              onChange(date);
              setIsOpen(false);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
