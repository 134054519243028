import { Link } from '~/components/shared';
import { Box, FlexLayout, Image, Text, theme, useScreenType } from '~/ui';

interface CountryPickerMenuItemProps {
  backgroundColor?: theme.Color;
  image: string;
  text: string;
  mobileWidth?: string;
  href?: string;
  onClick?: () => void;
}

function getFirstLettersFromWords(text: string) {
  return text
    .split(' ')
    .map((i) => i.charAt(0))
    .join('')
    .toLowerCase();
}

export const CountryPickerMenuItem: React.FC<CountryPickerMenuItemProps> = ({
  backgroundColor = 'black',
  image,
  text,
  mobileWidth = '100%',
  href,
  onClick,
}) => {
  const { isMobile } = useScreenType();

  const itemContent = (
    <Box as={href ? 'a' : 'div'} color="white" data-testid={`modal-picker-${getFirstLettersFromWords(text)}`}>
      <FlexLayout
        alignItems="center"
        backgroundColor={backgroundColor}
        px={4}
        py={[4, 20]}
        space={4}
        sx={{
          borderRadius: 's',
          width: [mobileWidth, '284px'],
          '&:hover': {
            color: 'gray-200',
          },
          '&:active': {
            color: 'gray-300',
          },
        }}
        tabIndex={href ? -1 : 0}
        onClick={onClick}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            onClick?.();
          }
        }}
      >
        <Text variant={isMobile ? 'compact-text-l' : 'compact-text-xl'}>{text}</Text>
        <Image src={image} />
      </FlexLayout>
    </Box>
  );

  if (!href) {
    return itemContent;
  }

  return (
    <Link href={href} withoutBox>
      {itemContent}
    </Link>
  );
};
