import { useScreenType } from '~/ui';

const screensMap = {
  isMobile: 'mobile',
  isTablet: 'tablet',
  isDesktop: 'desktop',
};

interface OnlyProps {
  children: any;
  for: 'mobile' | 'tablet' | 'desktop' | 'mobileAndTablet' | 'mobileAndDesktop' | 'tabletAndDesktop';
}

export const Only: React.FC<OnlyProps> = (props) => {
  const screens = props.for.toLocaleLowerCase().split('and');
  const screenTypesMap = useScreenType();

  for (const screen in screensMap) {
    if (screenTypesMap[screen] && screens.includes(screensMap[screen])) {
      return props.children;
    }
  }

  return null;
};
