import { useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { displayError } from '~/utils/errors';

export const QueryClientCreator = ({ children }) => {
  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: { queries: { staleTime: Infinity, onError: displayError, retry: 0 } },
    });
  }

  return <QueryClientProvider client={queryClientRef.current}>{children}</QueryClientProvider>;
};
