import { useRouter } from 'next/router';
import { useState } from 'react';

import { Link } from '~/components/shared';
import { FlexLayout, Icon, Pill, Text, theme } from '~/ui';

interface NavbarItemProps {
  icon?: theme.Icon;
  href: string;
  pillText?: string;
  text: string;
}

export const NavbarItem: React.FC<NavbarItemProps> = ({ href, icon, pillText, text }) => {
  const { asPath } = useRouter();
  const [isHovered, setIsHovered] = useState(false);

  const isActive = asPath === href || asPath.includes(href);

  return (
    <Link
      href={href}
      sx={{ flexShrink: 0, position: 'relative' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FlexLayout
        alignItems="center"
        space={2}
        sx={{
          '&::after': {
            visibility: isActive || isHovered ? 'visible' : 'hidden',
            content: '""',
            backgroundColor: isActive ? 'pink-500' : 'white-alpha-50',
            bottom: '-24px',
            height: '4px',
            left: 0,
            position: 'absolute',
            right: 0,
          },
        }}
      >
        <Text variant="compact-text-l">{text}</Text>
        {pillText && <Pill text={pillText} />}
        {icon && <Icon icon={icon} />}
      </FlexLayout>
    </Link>
  );
};
