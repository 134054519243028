import { isString, mapValues } from 'lodash';

/**
 * Typed wrapper around `Object.keys` function.
 * @param {Object} obj
 * @returns {Array} List of object keys with proper typing
 */
export function typedObjectKeys<T>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

export function clone<T>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}

export function objectValuesToLowerCase(object: { [key: string]: any }) {
  return mapValues(object, (value) => (isString(value) ? value.toLowerCase() : value));
}
