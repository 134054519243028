import { useRouter } from 'next/router';

import { Username } from '~/components/shared';
import { useAuthenticatedUser, useLogout } from '~/hooks/api';
import { getFeatureFlags, isEnabledFeature } from '~/services/featureFlags';
import { Box, FlexLayout, Icon, Only, theme, useIsMenuOpen } from '~/ui';

import { AccountMenuItem } from './AccountMenuItem';
import { useBookingsCount } from './hooks';

export const AccountMenu = () => {
  const { user } = useAuthenticatedUser();
  const { push } = useRouter();
  const [isMenuOpen, setIsMenuOpen] = useIsMenuOpen();
  const { logout } = useLogout();
  const bookingsCount = useBookingsCount();

  const { payment } = getFeatureFlags();

  return (
    <Box data-testid="navbar-user-menu-button">
      <Only for="mobile">
        {isMenuOpen && (
          <Box
            style={{
              backgroundColor: theme.colors['black-alpha-75'],
              bottom: 0,
              left: 0,
              position: 'fixed',
              right: 0,
              top: '72px',
              zIndex: 3,
            }}
          />
        )}
      </Only>
      <Box onOutsideClick={() => setIsMenuOpen(false)}>
        <FlexLayout space={2} onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <Username user={user} />
          <Icon icon={isMenuOpen ? 'chevronUp' : 'chevronDown'} />
        </FlexLayout>
        {isMenuOpen && (
          <Box
            sx={{
              position: 'fixed',
              right: '0',
              top: '72px',
              width: '269px',
              zIndex: 3,
            }}
          >
            <Box bg="black" sx={{ border: `1px solid ${theme.colors['gray-800']}` }}>
              <AccountMenuItem
                data-testid="account-menu-button"
                icon="user"
                text="Account"
                onClick={() => push('/account/settings')}
              />
              <Only for="mobileAndTablet">
                <AccountMenuItem
                  icon="calendar"
                  pillText={bookingsCount}
                  text="Bookings"
                  onClick={() => push('/account/bookings')}
                />
              </Only>
              {payment && (
                <AccountMenuItem
                  data-testid="payment-methods-menu-button"
                  icon="creditCard"
                  text="Payment Methods"
                  onClick={() => push('/account/payment')}
                />
              )}
              {isEnabledFeature('membership') && (
                <AccountMenuItem
                  data-testid="legends-menu-button"
                  icon="legends"
                  text="Legends"
                  onClick={() => push('/account/membership')}
                />
              )}
              <AccountMenuItem data-testid="logout-menu-button" icon="arrowRight" text="Log out" onClick={logout} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
