export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export async function tryCatch<T>(f: () => Promise<T> | T): Promise<[T, Error | undefined]> {
  try {
    const result = await f();
    return [result, undefined];
  } catch (error) {
    return [undefined as unknown as T, error as Error];
  }
}

// for actions with delayed results
// easier and safer than updating the API cache manually
// 10 seconds should be more than enough time for API to become "eventually consistent"
// more info here: https://en.wikipedia.org/wiki/Eventual_consistency
// NOTE: Don't use this when immediate response for an action is needed,
// use optimistic response in that case
export function eventually(callback) {
  delay(10000).then(callback);
}
