import remove from 'lodash/remove';

import { AddedPayment, AddGiftCardArgs, AddPaymentMethodArgs, DeletedPayment } from '~/apis';
import { GetMembershipTiers } from '~/apis/payments/getMembershipTiers';
import { GetPaymentMethods } from '~/apis/payments/getPaymentMethods';
import { backend } from '~/services/backendService';
import { mutation, NoArgs, query } from '~/utils/apiHooks';
import { eventually } from '~/utils/async';

export const existingPaymentMethodsQuery = query<NoArgs, GetPaymentMethods.RootObject>(
  backend.get,
  '/customers-v2/payers',
);

export const deletePaymentMethodMutation = mutation<{ cardId: string; userId: string }, DeletedPayment.RootObject>(
  backend.post,
  '/customers-v2/payers/disable-card',
  (client, { cardId }) => {
    existingPaymentMethodsQuery.setData(client, {}, (data) => {
      remove(data.payers[0].cards, (card) => card.id === cardId);
      remove(data.payers[0].giftCards, (giftCard) => giftCard.id === cardId);
    });

    eventually(() => {
      existingPaymentMethodsQuery.clear(client);
    });
  },
);

export const addPaymentMethodMutation = mutation<AddPaymentMethodArgs, AddedPayment.RootObject>(
  backend.post,
  '/customers-v2/payers',
  (client, args, result) => {
    const { cardBrand, expMonth, expYear, id, lastFour, customerId } = result.card;

    existingPaymentMethodsQuery.setData(client, {}, (data) => {
      if (!data.payers?.length) {
        data.payers = [{ customerId, cards: [], giftCards: [], provider: '' }];
      }
      // add new card in the beginning
      data.payers[0].cards.unshift({
        cardBrand,
        expMonth,
        expYear,
        id,
        lastFour,
        cardType: '', // not needed for internal client cache
        customerId,
      });
    });

    eventually(() => {
      existingPaymentMethodsQuery.clear(client);
    });
  },
);

export const addGiftCardPaymentMethodMutation = mutation<AddGiftCardArgs, AddedPayment.GiftCard>(
  backend.post,
  '/customers-v2/payers/save-gift-card',
  (client, args, result) => {
    const { id, createdAt, gan, ganSource, state, type, balanceMoney } = result;
    existingPaymentMethodsQuery.setData(client, {}, (data) => {
      // add new gift card in the beginning
      data.payers[0].giftCards.unshift({
        id,
        createdAt,
        gan,
        ganSource,
        state,
        type,
        balanceMoney,
      });
    });
    eventually(() => {
      existingPaymentMethodsQuery.clear(client);
    });
  },
);

export const membershipTiersQuery = query<NoArgs, GetMembershipTiers.RootObject>(backend.get, '/membership-tiers');
