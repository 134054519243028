import remove from 'lodash/remove';
import { QueryClient } from 'react-query';

import {
  AcceptBookingInviteArgs,
  BookingsCancelArgs,
  BookingsConfirm,
  BookingsConfirmArgs,
  BookingStatus,
  CreateBooking,
  CreateBookingData,
  CreateBookingInviteData,
  CreateBookingNote,
  CreateBookingNoteArgs,
  DeclineBookingInviteArgs,
  DeleteBookingInviteData,
  GetBooking,
  GetBookings,
} from '~/apis';
import { ApplyBookingDiscountRequest, RemoveBookingDiscountRequest } from '~/apis/booking/bookingDiscountRequest';
import { calculateBookingLimited } from '~/hooks/api/booking/calculateBooking';
import { createBookingLimited } from '~/hooks/api/booking/createBooking';
import { existingPaymentMethodsQuery } from '~/hooks/api/payments';
import { backend } from '~/services/backendService';
import { mutation, query } from '~/utils/apiHooks';
import { eventually } from '~/utils/async';
import { toDateString, todayString } from '~/utils/date';
import { clone } from '~/utils/object';

import { applyBookingDiscountLimited, removeBookingDiscountsLimited } from './bookingDiscount';

export const bookingCreateQuery = query<CreateBookingData, CreateBooking.RootObject>(
  createBookingLimited,
  '/bookings',
  {
    usePathAsUrl: false,
  },
);

export const bookingCalculateQuery = query<CreateBookingData, CreateBooking.RootObject>(
  calculateBookingLimited,
  '/bookings/calculate',
  {
    usePathAsUrl: false,
  },
);

export const applyBookingDiscountQuery = query<ApplyBookingDiscountRequest, CreateBooking.RootObject>(
  applyBookingDiscountLimited,
  '/bookings/discount',
  {
    usePathAsUrl: false,
  },
);

// TODO: this needs to be fixed since we have path parameters and body. this wont work just yet.
export const removeBookingDiscountQuery = query<RemoveBookingDiscountRequest, CreateBooking.RootObject>(
  removeBookingDiscountsLimited,
  '/bookings/:bookingId/discount',
  {
    usePathAsUrl: false,
  },
);

function getBookings({ status }: { status: BookingStatus }) {
  return backend.get('/bookings', { params: { status, date: status === 'confirmed' ? todayString() : undefined } });
}

export const bookingsQuery = query<{ status: BookingStatus }, GetBookings.RootObject>(getBookings, '/bookings', {
  usePathAsUrl: false,
});

export const bookingQuery = query<{ bookingId: string }, GetBooking.RootObject>(backend.get, '/bookings/:bookingId');

export const confirmBookingMutation = mutation<BookingsConfirmArgs.RootObject, BookingsConfirm.RootObject>(
  backend.post,
  '/bookings/confirm',
  async (client: QueryClient) => {
    await existingPaymentMethodsQuery.clear(client);

    // booking object returned from confirm endpoint is in "ordering" rather than "confirmed" state
    // we're re-fetching the confirmed bookings list after 10 seconds
    // 10 seconds should be enough time for it to go from "ordering" to "confirmed"
    eventually(() => bookingsQuery.clear(client, { status: 'confirmed' }));
  },
);

export const createBookingNoteMutation = mutation<CreateBookingNoteArgs.RootObject, CreateBookingNote.RootObject>(
  backend.post,
  '/bookings/note',
  (client, { booking: { id: bookingId }, note }) => {
    bookingQuery.setData(client, { bookingId }, (booking) => {
      booking.booking.canAddNotes = false;
      booking.note = note;
    });
  },
);

export const inviteUserMutation = mutation<CreateBookingInviteData, GetBooking.RootObject>(
  backend.post,
  '/bookings/invite',
  (client, { booking: { id: bookingId } }, booking) => {
    bookingQuery.setData(client, { bookingId }, booking);
  },
);

interface UninviteUserMutationProps extends DeleteBookingInviteData {
  isBookingOwner: boolean;
}

export const uninviteUserMutation = mutation<UninviteUserMutationProps, GetBooking.RootObject>(
  backend.post,
  '/bookings/uninvite',
  (client, { booking: { id: bookingId }, isBookingOwner }, booking) => {
    if (isBookingOwner) {
      bookingQuery.setData(client, { bookingId }, booking);

      eventually(() => {
        bookingQuery.clear(client);
        bookingsQuery.clear(client, { status: 'confirmed' });
      });
    } else {
      client.removeQueries(bookingId, { exact: true });
      bookingsQuery.setData(client, { status: 'confirmed' }, (data) => {
        remove(data.items, (item) => item.booking.id === bookingId);
      });
    }
  },
);

export const acceptInviteMutation = mutation<AcceptBookingInviteArgs, GetBooking.RootObject>(
  backend.post,
  '/bookings/accept-invite',
  (client, { booking: { id: bookingId } }, result) => {
    const booking = clone(result) as any;

    // Deleting properties because if you are not the booking owner, the backend will not return these values.
    delete booking.order;
    delete booking.payment;
    delete booking.pricing;

    bookingQuery.setData(client, { bookingId }, booking);
    bookingsQuery.setData(client, { status: 'confirmed' }, (data) => {
      const bookingIndex = data.items.findIndex((item) => item.booking.id === bookingId);
      data.items[bookingIndex] = booking as any;
    });

    eventually(() => {
      bookingQuery.clear(client);
      bookingsQuery.clear(client, { status: 'confirmed' });
    });
  },
);

export const declineInviteMutation = mutation<DeclineBookingInviteArgs, GetBooking.RootObject>(
  backend.post,
  '/bookings/decline-invite',
  (client, { booking: { id: bookingId } }, result) => {
    const booking = clone(result);

    bookingQuery.setData(client, { bookingId }, booking);
    bookingsQuery.setData(client, { status: 'confirmed' }, (data) => {
      remove(data.items, (item) => item.booking.id === bookingId);
    });

    eventually(() => {
      bookingQuery.clear(client);
      bookingsQuery.clear(client, { status: 'confirmed' });
    });
  },
);

export const cancelBookingMutation = mutation<BookingsCancelArgs.RootObject, GetBooking.RootObject>(
  backend.post,
  '/bookings/cancel',
  (client, { booking: { id: bookingId }, cancellation: { reason } }, result) => {
    const booking = clone(result);
    booking.booking.status = 'canceled';
    booking.booking.canAddNotes = false;
    booking.cancellation = { date: toDateString(new Date()), reason, userId: '' };
    booking.refund = { createdAt: toDateString(new Date()), issued: false, reason: '' };

    bookingQuery.setData(client, { bookingId }, booking);
    bookingsQuery.setData(client, { status: 'confirmed' }, (data) => {
      remove(data.items, (item) => item.booking.id === bookingId);
    });
    bookingsQuery.setData(client, { status: 'canceled' }, (data) => {
      data.items.push(booking as any);
    });

    eventually(() => {
      bookingQuery.clear(client);
      bookingsQuery.clear(client);
    });
  },
);
