import useHover from 'react-use/lib/useHover';
import { Box } from 'theme-ui';

import { FlexLayout, Icon, Text, theme } from '~/ui';

interface HoverableIconProps {
  icon?: theme.Icon;
  iconColor?: theme.Color;
  text: string;
  textColor?: theme.Color;
  position?: 'top' | 'left';
}

export const HoverableIcon: React.FC<HoverableIconProps> = ({
  icon = 'info',
  iconColor = 'white-alpha-75',
  text,
  textColor = 'white',
  position = 'left',
}) => {
  const [hoverable, hovered] = useHover(<Icon color={iconColor} icon={icon} sx={{ cursor: 'pointer' }} />);

  const isLeft = position === 'left';

  return (
    <FlexLayout alignItems="center" sx={{ position: 'relative' }}>
      {hoverable}
      {hovered && (
        <Box
          backgroundColor="gray-900"
          p={4}
          sx={{
            boxShadow: `2px 4px 6px ${theme.colors.black}`,
            position: 'absolute',
            right: isLeft ? '29px' : undefined,
            left: isLeft ? ['-90px', '-50px', 'auto'] : ['-100px', '-115px', '-160px'],
            bottom: isLeft ? undefined : '25px',
            width: ['243px', '270px', '363px'],
            zIndex: 1,
          }}
        >
          <Text color={textColor} variant="text-m">
            {text}
          </Text>
          <Box
            sx={{
              borderBottom: '8px solid transparent',
              borderLeft: `12px solid ${theme.colors['gray-900']}`,
              borderTop: '8px solid transparent',
              position: 'absolute',
              right: isLeft ? '-12px' : '50%',
              top: isLeft ? '50%' : undefined,
              bottom: isLeft ? undefined : '-14px',
              transform: isLeft ? undefined : 'rotate(90deg)',
            }}
          />
        </Box>
      )}
    </FlexLayout>
  );
};
