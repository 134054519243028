import { forwardRef, useRef } from 'react';
import useClickAway from 'react-use/lib/useClickAway';
import { Box as ThemeBox, BoxProps as ThemeBoxProps } from 'theme-ui';

import { styles, theme } from '~/ui';

export interface BoxProps extends ThemeBoxProps {
  color?: theme.Color;
  isDisabled?: boolean;
  maxLength?: number;
  type?: string;
  value?: any;
  onClick?: (event?: any) => void;
  onOutsideClick?: (event: Event) => void;
  target?: string;
  'data-testid'?: string;
}

export const Box: React.FC<BoxProps> = forwardRef<any, BoxProps>(
  ({ isDisabled = false, sx, onClick, onOutsideClick, 'data-testid': dataTestId, ...rest }, ref: any) => {
    const clickableStyle = isDisabled || onClick ? styles.interactions.clickable : {};
    /**
     * Note that this is not a good practice in general
     * the number of hooks should not depend on props because they have to be called in the same order for each render,
     * but I think it's okay here,
     * we'll just be careful never to switch between undefined and a truthy value for the handler.
     */
    const innerRef = useRef(ref);
    if (onOutsideClick) {
      useClickAway(innerRef, onOutsideClick);
    }

    return (
      <ThemeBox
        data-testid={dataTestId}
        ref={innerRef}
        sx={{ ...clickableStyle, ...sx }}
        onClick={!isDisabled && onClick ? onClick : undefined}
        {...{ disabled: isDisabled, ...rest }}
      />
    );
  },
);
