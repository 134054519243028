import { differenceInMinutes, format, set } from 'date-fns';
import inRange from 'lodash/inRange';
import { DateTime } from 'luxon';

import { reportError } from '~/services/logging';
import { getTodaysName } from '~/utils/date';

export function getTimeFromMilitary(military): string {
  const [hours, minutes] = splitMilitaryString(military.toString());
  const date = set(new Date(), { hours, minutes });
  return format(date, minutes === 0 ? 'h a' : 'p');
}

export function differenceInMinutesForMilitaryFormat(start: string | number, end: string | number) {
  return differenceInMinutes(convertMilitaryToDate(start), convertMilitaryToDate(end));
}

interface TimeDifferenceFromMilitaryArgs {
  end: number;
  start: number;
}

export function getTimeDifferenceObjectFromMilitary({ start, end }: TimeDifferenceFromMilitaryArgs) {
  const startTime = convertMilitaryToDate(start.toString());
  const endTime = convertMilitaryToDate(end.toString());
  const timeDiff = differenceInMinutes(endTime, startTime);

  const hours = Math.floor(timeDiff / 60);
  const minutes = timeDiff % 60;

  if (hours && minutes) {
    return { hours, minutes };
  }

  if (!minutes) {
    return { hours };
  }

  return { minutes };
}

export function getTimeDifferenceFromMilitary({ start, end }: TimeDifferenceFromMilitaryArgs): string {
  const { hours, minutes } = getTimeDifferenceObjectFromMilitary({ start, end });

  if (hours && minutes) {
    return `${hours} hrs ${minutes} min`;
  }

  if (!minutes) {
    return `${hours} hrs`;
  }

  return `${minutes} min`;
}

interface Availability {
  start: number;
  end: number;
  day: string;
}

export function getWorkingHoursFromAvailability(availabilities: Availability[]): string {
  const dayOfWeek = getTodaysName() as DayOfWeek;
  const currentAvailability = availabilities.find((availability) => availability.day === dayOfWeek);

  if (!currentAvailability) {
    const e = Error(`Given arena availability is not valid: ${currentAvailability}`);
    reportError(e, { message: 'ArenaTimeAvailError' });
    throw e;
  }

  const { start, end } = currentAvailability;

  const startTime = getTimeFromMilitary(start);
  const endTime = getTimeFromMilitary(end);

  return `${startTime} - ${endTime}`;
}

export type DayOfWeek = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';

export function parseMilitaryFromString(value: string | undefined): string | undefined {
  if (value === undefined) {
    return undefined;
  }

  if (value.length > 4 || value.length < 3) {
    return undefined;
  }

  const [hours, minutes] = splitMilitaryString(value);
  if (!Number.isInteger(hours) || !Number.isInteger(minutes)) {
    return undefined;
  }

  if (!inRange(hours, 25) || !inRange(minutes, 60)) {
    return undefined;
  }

  return value;
}

export function convertMilitaryToDate(military: string | number): Date {
  const [hours, minutes] = splitMilitaryString(typeof military === 'string' ? military : military.toString());
  return set(new Date(), { hours, minutes, seconds: 0 });
}

export function convertDateToMilitary(date: Date, timezone: string): string {
  return DateTime.fromJSDate(date, { zone: timezone }).toFormat('HHmm');
}

export function splitMilitaryString(value: string): [number, number] {
  const militaryString = value.padStart(4, '0');
  return [Number(militaryString.slice(0, 2)), Number(militaryString.slice(-2))];
}

type TimeOfDay = 'morning' | 'afternoon' | 'evening' | 'night';

export function getTimeOfDay(date: DateTime): TimeOfDay {
  const { hour } = date;

  if (hour < 5) {
    return 'night';
  }
  if (hour < 12) {
    return 'morning';
  }
  if (hour < 18) {
    return 'afternoon';
  }
  return 'evening';
}
