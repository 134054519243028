import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { useRef } from 'react';

import { getContentStackConfig } from '~/utils/environment';

function createContentStackApolloClient() {
  const { envName, deliveryToken, apiKey } = getContentStackConfig();
  return new ApolloClient({
    cache: new InMemoryCache(),
    uri: `https://graphql.contentstack.com/stacks/${apiKey}?environment=${envName}`,
    headers: {
      access_token: deliveryToken,
    },
  });
}

export const ContentStackGraphqlProvider = ({ children }) => {
  const apolloClientRef = useRef<ApolloClient<any>>();

  if (!apolloClientRef.current) {
    apolloClientRef.current = createContentStackApolloClient();
  }

  return <ApolloProvider client={apolloClientRef.current}>{children}</ApolloProvider>;
};
