import { getFeatureFlags } from '~/services/featureFlags';
import { FlexLayout, Text } from '~/ui';

import { useFooterLinks } from './hooks';
import { NavbarItem } from './NavbarItem';

export const MobileMenuFooterList = () => {
  const footerLinks = useFooterLinks();
  const { careers } = getFeatureFlags();

  if (!footerLinks) {
    return null;
  }

  return (
    <>
      <FlexLayout flexDirection="column" space={6}>
        <Text color="white-alpha-50" variant="text-s-medium">
          Resources
        </Text>
        {footerLinks.resourceItems.map((item) => (
          <NavbarItem href={item.href} key={item.href} text={item.text} />
        ))}
        {careers && <NavbarItem href="/careers" text="Careers" />}
      </FlexLayout>
      <FlexLayout flexDirection="column" space={6}>
        <Text color="white-alpha-50" variant="text-s-medium">
          About
        </Text>
        {footerLinks.aboutItems.map((item) => (
          <NavbarItem href={item.href} key={item.href} text={item.text} />
        ))}
      </FlexLayout>
    </>
  );
};
