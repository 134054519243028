import { ParallelLimiter } from 'parallel-limiter';

import { CreateBooking, CreateBookingData } from '~/apis';
import { backend } from '~/services/backendService';
import { tryCatch } from '~/utils/async';

const limiter = new ParallelLimiter({ maxParallel: 1 });

export async function calculateBooking(data: CreateBookingData) {
  const [res, error] = await tryCatch(() => backend.post<CreateBooking.RootObject>('/bookings/calculate', data));
  if (error) {
    throw error;
  }
  return res;
}

// sequentialize attempts of parallel executions
export const calculateBookingLimited = (data) => limiter.schedule(() => calculateBooking(data));
