import * as snippet from '@segment/snippet';
import NextHead from 'next/head';

import { segmentApiKey } from '~/services/analytics';
import { isEnabledFeature } from '~/services/featureFlags';
import { theme } from '~/ui';
import * as fonts from '~/ui/assets/fonts/NeusaNextPro';
import { fontFaces } from '~/ui/assets/fonts/NeusaNextPro/fontFaces';

/**
 * Generates Segment snippet to initiate event tracking.
 */
function renderSnippet() {
  const opts = {
    apiKey: segmentApiKey,
    page: {},
  };

  return snippet.max(opts);
}

const squareSdkUrl = process.env.NEXT_PUBLIC_SQUARE_APPLICATION_ID?.startsWith('sandbox')
  ? 'https://sandbox.web.squarecdn.com/v1/square.js'
  : 'https://web.squarecdn.com/v1/square.js';

export const Head: React.FC = () => {
  return (
    <NextHead>
      {Object.values(fonts).map((url) => {
        const extension = url.split('.').pop();
        return <link as="font" crossOrigin="anonymous" href={url} key={url} rel="preload" type={`font/${extension}`} />;
      })}
      <link href="/apple-touch-icon.png" rel="apple-touch-icon" sizes="180x180" />
      <link href="/favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
      <link href="/favicon-16x16.png" rel="icon" sizes="16x16" type="image/png" />
      <link href="/site.webmanifest" rel="manifest" />
      <link color="#5bbad5" href="/safari-pinned-tab.svg" rel="mask-icon" />
      <meta content="#da532c" name="msapplication-TileColor" />
      <style dangerouslySetInnerHTML={{ __html: fontFaces }} />
      <title>Belong</title>
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content={theme.colors.black} name="theme-color" />
      {/* works only in Safari on iphone and in US Region */}
      <meta
        content="app-id=1564338925, app-argument=https://apps.apple.com/us/app/belong-gaming/id1564338925"
        name="apple-itunes-app"
      />
      <script defer src={squareSdkUrl} type="text/javascript" />
      {segmentApiKey && <script dangerouslySetInnerHTML={{ __html: renderSnippet() }} />}
      {isEnabledFeature('adsense') && (
        <script
          async
          crossOrigin="anonymous"
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7730323299289117"
        />
      )}
    </NextHead>
  );
};
