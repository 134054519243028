import { FlexLayout, Text, theme } from '~/ui';

interface PriceBreakdownItemProps {
  children: any;
  name: string;
  variant?: theme.TextVariant;
  variantInfo?: theme.TextVariant;
  info?: string;
}

export const PriceBreakdownItem: React.FC<PriceBreakdownItemProps> = ({
  children,
  name,
  variant,
  variantInfo,
  info,
}) => {
  return (
    <FlexLayout alignItems="start" justifyContent="space-between">
      <FlexLayout flexDirection="column" space={2}>
        <Text variant={variant}>{name}</Text>
        {info && (
          <Text color="white-alpha-75" ml={4} variant={variantInfo ?? variant}>
            {info}
          </Text>
        )}
      </FlexLayout>
      <Text variant={variant}>{children}</Text>
    </FlexLayout>
  );
};
