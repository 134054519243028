import { ParallelLimiter } from 'parallel-limiter';

import { CreateBooking } from '~/apis';
import { ApplyBookingDiscountRequest, RemoveBookingDiscountRequest } from '~/apis/booking/bookingDiscountRequest';
import { backend } from '~/services/backendService';
import { tryCatch } from '~/utils/async';

const limiter = new ParallelLimiter({ maxParallel: 1 });

export async function applyBookingDiscount(data: ApplyBookingDiscountRequest) {
  const [res, error] = await tryCatch(() => backend.post<CreateBooking.RootObject>('/bookings/discount', data));
  if (error) {
    throw error;
  }
  return res;
}

// sequentialize attempts of parallel executions
export const applyBookingDiscountLimited = (data) => limiter.schedule(() => applyBookingDiscount(data));

export async function removeBookingDiscounts(bookingId: string, data: RemoveBookingDiscountRequest) {
  const [res, error] = await tryCatch(() =>
    backend.delete<CreateBooking.RootObject>(`/bookings/${bookingId}/discount`, { data }),
  );

  if (error) {
    throw error;
  }
  return res;
}

export const removeBookingDiscountsLimited = (bookingId, data) =>
  limiter.schedule(() => removeBookingDiscounts(bookingId, data));
