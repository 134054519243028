import { getAuth0AccessToken } from '~/components/Auth0Provider';
import { reportError } from '~/services/logging';
import { decodeIdToken } from '~/utils/auth';

export async function getAccessToken() {
  return await getAuth0AccessToken();
}

export async function getDecodedIdToken() {
  const token = await getAccessToken();

  if (!token) {
    const e = Error('Not authenticated');
    reportError(e, { message: 'AuthError', logOnly: true });
    throw e;
  }

  return decodeIdToken(token);
}
