import { FieldValidator } from 'final-form';
import { FieldMetaState, useField } from 'react-final-form';

import { TextInputWithFieldLabels, TextInputWithFieldLabelsProps } from '~/ui';

export interface FormTextInputFieldProps extends Omit<TextInputWithFieldLabelsProps, 'value' | 'onChange'> {
  name: string;
  validate?: FieldValidator<any>;
  'data-testid'?: string;
}

// allows submitError to be displayed even though the field is untouched
// needed to display an error in previous step of a form
// Note: regular validation error (non-submit error) is displayed ONLY if the field is touched
function getError(meta: FieldMetaState<any>) {
  if (meta.touched && meta.error) {
    return meta.error;
  }

  return meta.submitError;
}

export const FormTextInput: React.FC<FormTextInputFieldProps> = (props) => {
  const { name, validate, 'data-testid': dataTestId, ...rest } = props;
  const {
    input: { value, onChange, onBlur, onFocus },
    meta,
  } = useField(name, { validate });

  const error = getError(meta);

  return (
    <TextInputWithFieldLabels
      data-testid={dataTestId}
      value={value}
      onChange={(value) => onChange({ target: { value } })}
      {...rest}
      error={error}
      name={name}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};
