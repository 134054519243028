import { theme } from '~/ui';

export default function stylesFactory() {
  return {
    control: (base) => ({
      ...base,
      backgroundColor: theme?.colors['transparent'],
      border: 'none',
      boxShadow: 'none',
      height: theme?.sizes['input-l-height'],
      padding: 0,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 0,
    }),
    indicatorsContainer: (base) => ({
      ...base,
      padding: 0,
    }),
    input: (base) => ({
      ...base,
      color: theme?.colors['white'],
      margin: 0,
      marginLeft: '2px',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: theme?.colors['background-dark'],
      borderRadius: 0,
      boxShadow: 'none',
      margin: 0,
      zIndex: 10,
    }),
    menuList: (base) => ({
      ...base,
      borderBottomRightRadius: theme?.radii['s'],
      borderBottomLeftRadius: theme?.radii['s'],
      padding: 0,
    }),
    noOptionsMessage: (base) => ({
      ...base,
      padding: 0,
    }),
    option: (base) => ({
      ...base,
      backgroundColor: theme?.colors['transparent'],
      padding: 0,
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme?.colors['black']}`,
      },
    }),
    valueContainer: (base) => ({
      ...base,
      padding: 0,
    }),
  };
}
