import NextLink from 'next/link';
import React from 'react';

import { Box, BoxProps } from '~/ui';

export interface LinkProps extends BoxProps {
  href: string;
  onClick?: (event: Event) => void;
  withoutBox?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
}

function isExternal(href: string) {
  return href.startsWith('http') || href.startsWith('mailto:');
}

/**
 * Next.js is taking care of running child element onClick function
 * ref: https://github.com/vercel/next.js/blob/canary/packages/next/client/link.tsx#L275
 */

export const Link: React.FC<LinkProps> = ({
  children,
  href,
  sx = {},
  onClick,
  withoutBox = false,
  target,
  ...rest
}) => {
  const computedTarget = target ?? (isExternal(href) ? target : undefined);

  if (withoutBox) {
    return (
      <NextLink href={href} passHref>
        {React.cloneElement(children as any, { target: computedTarget, as: 'a' })}
      </NextLink>
    );
  }

  return (
    <NextLink href={href} passHref>
      <Box
        as="a"
        color="white"
        sx={{ width: 'fit-content', ...sx }}
        target={computedTarget}
        onClick={onClick}
        {...rest}
      >
        {children}
      </Box>
    </NextLink>
  );
};
