import { FlexLayout, Image, Text } from '~/ui';

export const MembershipBookingBadge = () => {
  return (
    <FlexLayout bg="white" px={3} py={2} space={2} sx={{ borderRadius: '48px', height: '32px', width: '178px' }}>
      <Image src="/assets/images/legends-logo-small-black.svg" />
      <Text color="black" variant="text-s-medium">
        Membership Booking
      </Text>
    </FlexLayout>
  );
};
