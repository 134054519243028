/**
 * Allows for piece of state to be transferred between routes without putting it in query args
 * User's refresh of the current page will delete the transferred state (which isn't true when query params are used)
 */

import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useRef } from 'react';

const StatefulRouterContext = createContext<any>(undefined);

export function StatefulRouter({ children }) {
  // piece of state associated with the current route
  const routerStateRef = useRef({
    belongsToFutureRoute: true,
    pathState: {},
  });

  // indicates whether the currently set route state belongs to the current route or to the next one
  // flag is needed to decide whether the state should be purged on route change
  const { events } = useRouter();

  useEffect(() => {
    function routeChangeStart() {
      if (routerStateRef.current.belongsToFutureRoute) {
        // if state was previously changed and awaits for route to be changed to become accessible...
        // ...flip the flag and make the state accessible
        // (because the route is now changed)
        routerStateRef.current.belongsToFutureRoute = false;
      } else {
        routerStateRef.current.pathState = {};
      }
    }

    // set handler on mount
    events.on('routeChangeStart', routeChangeStart);
    return () => {
      // kill the handler on unmount
      events.off('routeChangeStart', routeChangeStart);
    };
  }, []);

  return <StatefulRouterContext.Provider children={children} value={{ routerStateRef }} />;
}

export function useStatefulRouter() {
  const { routerStateRef } = useContext(StatefulRouterContext) ?? {};
  const router = useRouter();
  if (!routerStateRef) {
    return { ...router, state: {}, pushWithState: () => undefined };
  }

  function pushWithState(
    url: string,
    pathState: Record<string, unknown>,
    { method = 'push' }: { method?: 'push' | 'replace' } = {},
  ) {
    // associate the state with the route that's about to be pushed
    routerStateRef.current = {
      belongsToFutureRoute: true,
      pathState,
    };

    // change the route
    // when route finally changes, setStateBelongsToFutureRoute is called from `handleRouteComplete` causing...
    // ... stateBelongsToFutureRoute flag to be set to false
    if (method === 'push') {
      return router.push(url);
    } else {
      return router.replace(url);
    }
  }

  const { belongsToFutureRoute, pathState } = routerStateRef.current;
  return { ...router, state: belongsToFutureRoute ? {} : pathState, pushWithState };
}
