import { Link } from '~/components/shared';
import { LinkButton, LinkButtonProps } from '~/ui';

export interface AnchorLinkButtonProps extends Omit<LinkButtonProps, 'onClick'> {
  href: string;
}

export const AnchorLinkButton: React.FC<AnchorLinkButtonProps> = ({ href, ref, ...rest }) => {
  return (
    <Link href={href}>
      <LinkButton {...rest} />
    </Link>
  );
};
