import { fontStyles } from '~/ui/theme/typography/fonts';

import * as icons from '../assets/icons';
import borders from './borders';
import { alphas, palette } from './colors';
import radii from './radii';
import sizes from './sizes';
import space from './space';
import typography from './typography';

const themeUiColors = {
  background: palette['background-dark'],
  text: palette.white,
  primary: palette['pink-500'],
};

export const colors = {
  ...alphas,
  ...palette,
  ...themeUiColors,
};

export { borders, radii, sizes, space };

export const fonts = typography.fonts;
export const fontWeights = typography.fontWeights;
export const text = typography.variants;
export * from './breakpoints';
export * as styles from './styles';

export type Color = keyof typeof colors;
export type Icon = keyof typeof icons;
export type TextVariant = keyof typeof text;

// TODO - move to specific file
export const forms = {
  textarea: {
    primary: {
      fontFamily: fonts.body,
      fontSize: '16px',
      fontStyle: fontStyles.normal,
      fontWeight: fontWeights.normal,
      lineHeight: '24px',
      textTransform: 'none',
    },
  },
};
