import { Box, BoxProps, FlexLayout, Icon, LoadingSpinner, styles, Text, theme } from '~/ui';

const NullableIcon: React.FC<{ icon?: theme.Icon }> = ({ icon }) => {
  return icon ? <Icon data-testid="link-button-icon" icon={icon} /> : null;
};

export interface LinkButtonContentProps {
  iconLeft?: theme.Icon;
  iconRight?: theme.Icon;
  isLoading?: boolean;
  text: string;
  color: theme.Color;
}

const LinkButtonContent: React.FC<LinkButtonContentProps> = ({ isLoading, iconLeft, iconRight, text, color }) => {
  if (isLoading) {
    if (iconLeft && iconRight) {
      return (
        <>
          <LoadingSpinner color={color} />
          <Text variant="compact-text-l">{text}</Text>
          {<Icon icon={iconRight} />}
        </>
      );
    }
    return (
      <>
        {iconRight ? <NullableIcon icon={iconLeft} /> : <LoadingSpinner color={color} />}
        <Text variant="compact-text-l">{text}</Text>
        {iconRight && !iconLeft ? <LoadingSpinner color={color} /> : <NullableIcon icon={iconRight} />}
      </>
    );
  }

  return (
    <>
      <NullableIcon icon={iconLeft} />
      <Text variant="compact-text-l">{text}</Text>
      <NullableIcon icon={iconRight} />
    </>
  );
};

const variantsMap = {
  primary: {
    color: 'pink-500' as theme.Color,
    activeStyles: {
      color: 'pink-700' as theme.Color,
    },
    hoverStyles: {
      color: 'pink-600' as theme.Color,
    },
  },
  secondary: {
    color: 'white' as theme.Color,
    activeStyles: {
      color: 'gray-300' as theme.Color,
    },
    hoverStyles: {
      color: 'gray-200' as theme.Color,
    },
  },
};

export interface LinkButtonProps extends BoxProps {
  iconLeft?: theme.Icon;
  iconRight?: theme.Icon;
  isLoading?: boolean;
  text: string;
  variant?: keyof typeof variantsMap;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  iconLeft,
  iconRight,
  isDisabled = false,
  isLoading = false,
  text,
  variant = 'primary',
  onClick,
  sx,
  as = 'button',
  ...rest
}) => {
  const { color, activeStyles, hoverStyles } = variantsMap[variant];

  return (
    <Box
      as={as}
      isDisabled={isDisabled || isLoading}
      sx={{
        ...styles.interactions.clickable,
        bg: 'transparent',
        border: 'none',
        color,
        outline: 'none',
        p: 0,
        width: 'fit-content',
        display: as === 'a' ? 'flex' : 'initial',
        justifyContent: as === 'a' ? 'center' : 'initial',
        '&:hover': hoverStyles,
        '&:active': activeStyles,
        ...sx,
      }}
      onClick={onClick}
      {...rest}
    >
      <FlexLayout alignItems="center" space={3}>
        <LinkButtonContent color={color} iconLeft={iconLeft} iconRight={iconRight} isLoading={isLoading} text={text} />
      </FlexLayout>
    </Box>
  );
};

export const linkButtonVariantsMap = variantsMap;
