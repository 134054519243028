import { useState } from 'react';

import { FormTextInput } from './FormTextInput';

type FormPasswordInputProps = Omit<
  React.ComponentProps<typeof FormTextInput>,
  'type' | 'onClickRightIcon' | 'iconRight'
>;

export const FormPasswordInput: React.FC<FormPasswordInputProps> = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <FormTextInput
      {...props}
      iconRight={isPasswordVisible ? 'eyeOff' : 'eye'}
      type={isPasswordVisible ? 'text' : 'password'}
      onClickRightIcon={() => setIsPasswordVisible(!isPasswordVisible)}
    />
  );
};
