import { Fragment } from 'react';

import { Link } from '~/components/shared';
import { useCurrentUrl } from '~/hooks/routing';
import { Divider, FlexLayout, Icon, theme } from '~/ui';
import { copyToClipboard } from '~/utils/interaction';

const hoverStyles = {
  transition: 'color .12s ease',
  '&:hover': { color: 'pink-600' },
  '&:active': { color: 'pink-700' },
};

const socialNetworks = [
  {
    target: 'facebook' as theme.Icon,
    url: 'https://www.facebook.com/sharer/sharer.php?u=',
  },
  {
    target: 'linkedin' as theme.Icon,
    url: 'https://www.linkedin.com/sharing/share-offsite/?url=',
  },
  {
    target: 'twitter' as theme.Icon,
    url: 'https://twitter.com/intent/tweet?url=',
  },
];

interface ShareSocialsProps {
  direction?: 'row' | 'column';
}

export const ShareSocials: React.FC<ShareSocialsProps> = ({ direction = 'column' }) => {
  const currentUrl = useCurrentUrl();
  const isColumn = direction === 'column';

  return (
    <FlexLayout
      bg="gray-900"
      flexDirection={isColumn ? 'column' : 'row'}
      p={4}
      space={5}
      sx={{ width: 'fit-content', height: 'fit-content' }}
    >
      {socialNetworks.map((network) => (
        <Fragment key={network.target}>
          <Link href={network.url + currentUrl} sx={{ ...hoverStyles }} target="_blank">
            <Icon icon={network.target} />
          </Link>
          <Divider color="white-alpha-10" orientation={isColumn ? 'horizontal' : 'vertical'} />
        </Fragment>
      ))}
      <Icon
        icon="copy"
        sx={{ ...hoverStyles }}
        onClick={() => {
          if (currentUrl) {
            copyToClipboard(currentUrl, 'URL copied to clipboard successfully.');
          }
        }}
      />
    </FlexLayout>
  );
};
