import GoogleMapReact from 'google-map-react';

import { FlexLayout, Icon } from '~/ui';

import { createOptions } from './createOptions';
import { withCoordinates } from './withCoordinates';

const DEFAULT_ZOOM = 15;

interface ArenaMapProps {
  coordinates: Coordinates;
}

interface Coordinates {
  lat: number;
  lng: number;
}

export const ArenaMap: React.FC<ArenaMapProps> = ({ coordinates }) => {
  const MarkerIcon = withCoordinates(Icon);

  return (
    <FlexLayout
      alignItems="center"
      flexGrow={1}
      justifyContent="center"
      sx={{
        '>div': {
          minHeight: '240px',
        },
        'div.gm-style-cc, img[src="https://maps.gstatic.com/mapfiles/api-3/images/google_white5.png"]': {
          display: 'none',
        },
      }}
    >
      <GoogleMapReact
        // TODO Change key
        bootstrapURLKeys={{ key: 'AIzaSyDXKP7Comx-FfGQxfwo_tIu8UJthH2WAqQ' }}
        defaultCenter={coordinates}
        defaultZoom={DEFAULT_ZOOM}
        options={createOptions}
      >
        <MarkerIcon icon="belong" lat={coordinates.lat} lng={coordinates.lng} />
      </GoogleMapReact>
    </FlexLayout>
  );
};
