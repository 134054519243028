import { Box, FlexLayout } from '~/ui';

export interface SwitchProps {
  isDisabled?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
  'data-testid'?: string;
}

export const Switch: React.FC<SwitchProps> = ({
  isDisabled = false,
  value = false,
  onChange,
  'data-testid': dataTestId,
}) => {
  return (
    <FlexLayout
      alignItems="center"
      bg={value ? 'pink-500' : 'gray-800'}
      data-testid={dataTestId}
      flexShrink={0}
      isDisabled={isDisabled}
      sx={{
        borderRadius: '72px',
        height: '24px',
        position: 'relative',
        width: '48px',
        '&:hover': {
          bg: value ? 'pink-600' : 'gray-700',
        },
      }}
      onClick={() => onChange(!value)}
    >
      <Box
        bg="white"
        mx={1}
        sx={{
          borderRadius: 'round',
          height: '16px',
          left: value ? 'calc(100% - 16px - 8px)' : 0,
          position: 'absolute',
          transition: 'left ease .25s',
          width: '16px',
        }}
      />
    </FlexLayout>
  );
};
