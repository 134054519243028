import { fetchContentStackItem, fetchSortedContentStackData } from '~/services/contentStack';

import { FetchNewsItems } from './getNewsItems.d';

export const getNewsItems = async () =>
  fetchSortedContentStackData<FetchNewsItems.Entry[][]>('news_items', 'publish_date', 'descending');
export const getNewsItem = async (newsItemUrl: string) =>
  fetchContentStackItem<FetchNewsItems.Entry>('news_items', 'url', `/${newsItemUrl}`);

export * from './ContentStackSchema.d';
export * from './getAboutPage.d';
export * from './getAccountPage.d';
export * from './getArenaContent.d';
export * from './getArenaPage.d';
export * from './getBookingPage.d';
export * from './getContactUsPage.d';
export * from './getContentTypes.d';
export * from './getCovidPage.d';
export * from './getFaqPage.d';
export * from './getFeaturedNewsItems.d';
export * from './getFooterNavigation.d';
export * from './getGamesPage.d';
export * from './getHeaderNavigation.d';
export * from './getHomePage.d';
export * from './getMembershipsPage.d';
export * from './getNewsItems.d';
export * from './getPrivacyPolicyPage.d';
export * from './getProgramsPage.d';
export * from './getTermsAndConditionsPage.d';
export * from './getTermsOfServicePage.d';
