import { FormApi, FormSubscription } from 'final-form';
import { Form as RFForm, FormRenderProps } from 'react-final-form';

interface FormProps {
  onSubmit(values: any, form: FormApi<any, any>);
  render(props: FormRenderProps);
  subscription?: FormSubscription;
  initialValues?: any;
}

export const Form: React.FC<FormProps> = ({ render, onSubmit, initialValues, subscription }) => {
  return <RFForm initialValues={initialValues} render={render} subscription={subscription} onSubmit={onSubmit} />;
};
