import { AxiosRequestConfig } from 'axios';
import decode from 'jwt-decode';

export interface Address {
  formatted: string;
}

export interface IdToken {
  sub: string;
  email_verified: boolean;
  birthdate: string;
  iss: string;
  'cognito:username': string;
  'custom:marketingOptIn': string;
  'custom:smsNotifications'?: string;
  'custom:emailNotifications'?: string;
  aud: string;
  event_id: string;
  'custom:zip': string;
  token_use: string;
  auth_time: number;
  name: string;
  'custom:displayName': string;
  'custom:termsAccepted': string;
  exp: number;
  iat: number;
  family_name: string;
  email: string;
  phone_number: string;
  phone_number_verified: boolean;
}

export function decodeIdToken(token: string) {
  return decode<IdToken>(token);
}

export function decorateWithBearerToken(config: AxiosRequestConfig, token: string) {
  if (!config.headers) {
    config.headers = {};
  }

  config.headers.authorization = `Bearer ${token}`;

  return config;
}
