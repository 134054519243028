import { useRouter } from 'next/router';
// import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
// import { isEnabledFeature } from '~/services/featureFlags';

const COUNTRY_SELECTED_DOMAIN = process.env.NEXT_PUBLIC_COUNTRY_SELECTED_DOMAIN;

const never = new Date('2100-1-1');

export function useCountrySelectionChecker() {
  const { query: { country } = {}, isReady } = useRouter();
  const [cookies, setCookie] = useCookies(['countrySelected']);

  const countrySelected = cookies.countrySelected || country;

  return {
    markCountrySelected(country: 'uk' | 'usa') {
      setCookie('countrySelected', country, { domain: COUNTRY_SELECTED_DOMAIN, expires: never });
    },
    isCountrySelected: !!countrySelected,
    countrySelected: countrySelected as 'uk' | 'usa',
    isReady,
  };
}

export function useCountryRedirection() {
  return;
  // if (!isEnabledFeature('uk-redirect')) {
  //   return;
  // }

  // const { push } = useRouter();
  // const { countrySelected } = useCountrySelectionChecker();

  // useEffect(() => {
  //   if (countrySelected === 'uk') {
  //     push('https://belong.gg');
  //   }
  // }, []);
}
