import { QueryClient, useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query';
import { InvalidateQueryFilters } from 'react-query/types/core/types';
import { UseQueryOptions } from 'react-query/types/react/types';

import { clone } from '~/utils/object';

function applyVariablesToPath(path: string, vars: any) {
  if (!vars) {
    return path;
  }

  let result = path;

  Object.keys(vars).forEach((key) => {
    result = result.replace(`:${key}`, vars[key]);
  });

  return result;
}

export function query<TInput = any, TOutput = any>(func, path: string, { usePathAsUrl = true } = {}) {
  const queryFn = (args) => (usePathAsUrl ? func(applyVariablesToPath(path, args), args) : func(args));

  function use(args: TInput | undefined | '', options: Omit<UseQueryOptions<TOutput>, 'queryFn' | 'queryKey'> = {}) {
    return useQuery<TOutput>({
      queryFn: () => queryFn(args),
      queryKey: key(args || undefined),
      enabled: !!args,
      ...options,
    });
  }

  function key(args?: TInput) {
    if (args) {
      return [path, args];
    }
    return path;
  }

  async function clear(client: QueryClient, args?: TInput, options?: InvalidateQueryFilters) {
    await client.invalidateQueries(key(args), options);
  }

  async function remove(client: QueryClient, args?: TInput, options?: InvalidateQueryFilters) {
    await client.removeQueries(key(args), options);
  }

  function useInvalidator() {
    const client = useQueryClient();
    return (args?: TInput) => client.invalidateQueries(key(args));
  }

  function useDataSetter() {
    const client = useQueryClient();

    return (input: TInput, result: TOutput) => client.setQueryData(key(input), result);
  }

  function setData(client: QueryClient, input: TInput, setterOrData: TOutput | ((result: TOutput) => void)) {
    if (!(setterOrData instanceof Function)) {
      client.setQueryData(key(input), setterOrData);
      return;
    }
    const oldData = client.getQueryData<TOutput>(key(input));
    if (oldData === undefined) {
      return;
    }
    const data = clone(oldData);
    setterOrData(data);
    client.setQueryData(key(input), data);
  }

  async function prefetch(args: TInput, client?: QueryClient) {
    const result: TOutput = await queryFn(args);
    client?.setQueryData(key(args), result);
    return result;
  }

  return { use, key, clear, useInvalidator, useDataSetter, setData, prefetch, remove };
}

export function mutation<TInput, TOutput>(
  func,
  path: string,
  onSuccess?: (client: QueryClient, args: TInput, result: TOutput) => void | Promise<void>,
) {
  function use(options: UseMutationOptions<TOutput, any, TInput> = {}) {
    const client = useQueryClient();

    async function handleSuccess(result: TOutput, args: TInput, ctx) {
      await options.onSuccess?.(result, args, ctx);
      await onSuccess?.(client, args, result);
    }

    return useMutation<TOutput, any, TInput>((args) => func(applyVariablesToPath(path, args), args), {
      ...options,
      onSuccess: handleSuccess,
    });
  }

  return { use };
}

export type NoArgs = Record<string, never>;
