import DOMPurify from 'isomorphic-dompurify';
import { SxStyleProp } from 'theme-ui';

import { Box } from '~/ui';

interface SanitizeProps {
  html: string;
  sx?: SxStyleProp;
}

export const Sanitize: React.FC<SanitizeProps> = ({ html, sx = {} }) => {
  const clean = DOMPurify.sanitize(html, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  });

  return <Box dangerouslySetInnerHTML={{ __html: clean }} sx={sx} />;
};
