import { Property } from 'csstype';
import { SxStyleProp } from 'theme-ui';

import { FlexLayout, Icon, Text, theme } from '~/ui';

interface TextIconBoxProps {
  alignItems?: Property.AlignItems | Property.AlignItems[];
  color?: theme.Color;
  icon: theme.Icon;
  iconPosition?: 'left' | 'right';
  justifyContent?: Property.JustifyContent | Property.JustifyContent[];
  space?: number | number[];
  subtext?: string | React.ReactNode;
  sx?: {
    icon?: SxStyleProp;
    text?: SxStyleProp;
    subtext?: SxStyleProp;
  };
  text: string;
  variant?: theme.TextVariant;
  onClick?(): void;
}

export const TextIconBox: React.FC<TextIconBoxProps> = ({
  alignItems = 'center',
  color = 'white',
  icon,
  iconPosition = 'left',
  justifyContent,
  space = 4,
  subtext,
  sx,
  text,
  variant = 'text-m-medium',
  onClick,
}) => {
  return (
    <FlexLayout alignItems={alignItems} justifyContent={justifyContent} space={space} onClick={onClick}>
      {iconPosition === 'left' && <Icon color={color} icon={icon} sx={sx?.icon} />}
      <FlexLayout flexDirection="column" space={1}>
        <Text color={color} sx={sx?.text} variant={variant}>
          {text}
        </Text>
        {subtext && typeof subtext === 'string' ? (
          <Text color="white-alpha-75" sx={sx?.subtext} variant="text-s">
            {subtext}
          </Text>
        ) : (
          subtext
        )}
      </FlexLayout>
      {iconPosition === 'right' && <Icon color={color} icon={icon} sx={sx?.icon} />}
    </FlexLayout>
  );
};
