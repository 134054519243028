import initial from 'lodash/initial';
import last from 'lodash/last';

export function insertBeforeLast<T>(words: T[], connectWord = 'and'): string {
  if (words.length === 1) {
    return words.join();
  }

  return initial(words).join(', ') + ` ${connectWord} ${last(words)}`;
}

// e.g. sliceArray(images, 6, 2)
// where:
// first param -> images -> array of images
// sedond param -> number 6 -> cut to 6 images
// third param -> number 2 -> second set of images/second page
export function sliceArray(array: any[], sliceSize: number, numberOfWantedDataSet?: number) {
  if (numberOfWantedDataSet) {
    return array.slice((numberOfWantedDataSet - 1) * sliceSize, numberOfWantedDataSet * sliceSize);
  }

  return array.slice(0, sliceSize);
}
