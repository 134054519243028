import { AxiosError } from 'axios';

import { includes } from '~/utils/string';

export function errorMatches(error: AxiosError<any>, errorCode: string) {
  if (error.code === errorCode) {
    return true;
  }

  const errorCodes = error.response?.data?.errors?.map((error) => error.code);
  if (!errorCodes) {
    return false;
  }

  return errorCodes.includes(errorCode);
}

export function errorContains(error: AxiosError<any>, messagePart: string) {
  const extraMessages: [] | string[] = error.response?.data?.errors?.map((error) => error?.message ?? '') ?? [];

  const messages: string[] = [error.message ?? '', error.response?.data?.message ?? '', ...extraMessages];

  return messages.some((message) => includes(message, messagePart));
}
