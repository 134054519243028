import { DialogContent, DialogOverlay } from '@reach/dialog';

import { Box, theme } from '~/ui';

interface ModalProps {
  ariaLabel?: string;
  bg?: theme.Color;
  isVisible: boolean;
  shouldDisableAutoHide?: boolean;
  width?: string;
  onHide?(): void;
}

export const Modal: React.FC<ModalProps> = ({
  ariaLabel,
  bg = 'black',
  children,
  isVisible = false,
  shouldDisableAutoHide = false,
  width = 'modal-m-width',
  onHide,
}) => {
  function autoHide() {
    // eslint-disable-next-line mdx/no-unused-expressions
    !shouldDisableAutoHide && onHide?.();
  }

  if (!isVisible) {
    return null;
  }

  return (
    <DialogOverlay
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 4,
        backgroundColor: theme.colors['black-alpha-60'],
        padding: theme.space[4],
      }}
      onDismiss={autoHide}
    >
      <DialogContent aria-label={ariaLabel || 'Modal'} style={{ width: `min(100%, ${theme.sizes[width]})` }}>
        <Box bg={bg} p={5}>
          {children}
        </Box>
      </DialogContent>
    </DialogOverlay>
  );
};
