import { FlexLayout, Icon, Text, theme } from '~/ui';

function checkboxValueToIcon(value: boolean | null): theme.Icon | undefined {
  if (value === true) {
    return 'checkmark';
  }
  if (value === null) {
    return 'horizontalLine';
  }

  return undefined;
}

export interface CheckboxProps {
  isDisabled?: boolean;
  label?: string | JSX.Element;
  value: boolean | null;
  onChange: (value: boolean | null) => void;
  tabIndex?: number | undefined;
  'data-testid'?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  isDisabled = false,
  label,
  value,
  onChange,
  tabIndex,
  'data-testid': dataTestId,
}) => {
  const icon = checkboxValueToIcon(value);

  const isActive = value === true || value === null;

  function handleToggle() {
    onChange(!value);
  }

  return (
    <FlexLayout alignItems="flex-start" isDisabled={isDisabled} space={4} onClick={handleToggle}>
      <FlexLayout
        alignItems="center"
        aria-checked="false"
        data-testid={dataTestId}
        flexShrink={0}
        justifyContent="center"
        role="checkbox"
        sx={{
          bg: isActive ? 'pink-500' : 'gray-800',
          borderRadius: 's',
          height: '24px',
          width: '24px',
          '&:hover': {
            bg: isActive ? 'pink-600' : 'gray-700',
          },
        }}
        tabIndex={tabIndex || 0}
        onKeyPress={(event) => {
          const { key } = event;
          if (key === 'Enter' || key === ' ') {
            handleToggle();
          }
        }}
      >
        {icon && <Icon icon={icon} />}
      </FlexLayout>
      {label && typeof label === 'string' ? <Text variant="text-l">{label}</Text> : label}
    </FlexLayout>
  );
};
