import { Dot } from '~/components/shared';
import { BookingUIStatus } from '~/pages-components/SingleBookingPage/utils';
import { Box, FlexLayout, Text } from '~/ui';
import { Color } from '~/ui/theme';
import { capitalizeWordsWithChar } from '~/utils/string';

const statusColorsMap: Partial<Record<BookingUIStatus, Color>> = {
  accepted: 'green-500',
  canceled: 'red-500',
  completed: 'green-500',
  confirmed: 'green-500',
  'players pending': 'yellow-500',
};

interface BookingStatusIndicatorProps {
  status: BookingUIStatus;
  text?: string | JSX.Element;
}

export const BookingStatusIndicator: React.FC<BookingStatusIndicatorProps> = ({ status, text }) => {
  return (
    <Box>
      <FlexLayout alignItems="center" flexDirection="row" space={2}>
        <Dot color={statusColorsMap[status.toLowerCase()]} size="8px" />
        <Text color="white" variant="text-m-medium">
          {text ?? capitalizeWordsWithChar(status)}
        </Text>
      </FlexLayout>
    </Box>
  );
};
