import { bookingsQuery, footerQuery, navbarQuery, useAuthenticatedUser } from '~/hooks/api';
import { isEnabledFeature } from '~/services/featureFlags';

export function useBookingsCount() {
  const { user } = useAuthenticatedUser();
  const { data: confirmedBookings } = bookingsQuery.use(user ? { status: 'confirmed' } : undefined);
  const { data: checkedInBookings } = bookingsQuery.use(user ? { status: 'checkedIn' } : undefined);

  const bookingsCount = (confirmedBookings?.items.length ?? 0) + (checkedInBookings?.items.length ?? 0);

  return (bookingsCount ?? '...').toString();
}

export function useHeaderLinks() {
  const { data, isLoading } = navbarQuery.use({});

  if (isLoading || !data) {
    return null;
  }

  const hrefsToRemove: Array<string> = [];
  if (!isEnabledFeature('membership')) {
    hrefsToRemove.push('/memberships');
  }

  return data[0][0].links.filter((link) => !hrefsToRemove.includes(link.path.href));
}

export function useFooterLinks() {
  const { data, isLoading } = footerQuery.use({});

  if (isLoading || !data) {
    return null;
  }

  const { about, resources, social_networks } = data[0][0];

  const aboutItems = mapLinkItems(about);
  const resourceItems = mapLinkItems(resources);

  return { aboutItems, resourceItems, socialNetworks: social_networks };
}

function mapLinkItems(items) {
  return items.map(({ path }) => ({ text: path.title, href: path.href }));
}
