import isAlphanumeric from 'validator/lib/isAlphanumeric';
import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isNumeric from 'validator/lib/isNumeric';

import { DateMap, parseDate, parseDateObject } from '~/utils/date';

export type Validator = (value: string) => string | undefined;

export const combine =
  (...validators: Validator[]) =>
  (value) => {
    for (const validator of validators) {
      const msg = validator(value);
      if (msg) {
        return msg;
      }
    }

    return undefined;
  };

function isUppercase(str: string) {
  return str == str.toUpperCase() && str != str.toLowerCase();
}

export const email = (message: string) => (value) => value && isEmail(value) ? undefined : message;

export const minLength = (message: string, length: number) => (value: string) =>
  value && value.length >= length ? undefined : message;

export const maxLength = (message: string, length: number) => (value: string) =>
  value && value.length <= length ? undefined : message;

export const containsUppercase = (message: string) => (value: string) =>
  value.split('').some(isUppercase) ? undefined : message;

export const containsNumber = (message: string) => (value: string) =>
  value.split('').some((ch) => isNumeric(ch)) ? undefined : message;

export const startsOrEndsWithWhitespace = (message: string) => (value: string) =>
  value.startsWith(' ') || value.endsWith(' ') ? message : undefined;

export const isEmpty = (message: string) => (value: string) => value && value.trim().length > 0 ? undefined : message;

export const name = (field: string) =>
  combine(
    isEmpty(`${field} is required`),
    required(`${field} is required`),
    minLength(`${field} is too short`, 2),
    maxLength(`${field} is too long`, 50),
    startsOrEndsWithWhitespace(`${field} must not start or end with a whitespace character`),
  );

export const password = combine(
  minLength('Password is too short.', 8),
  containsUppercase('Password must contain at least one uppercase character'),
  containsNumber('Password must contains at least one number'),
  startsOrEndsWithWhitespace('Password must not start or end with a whitespace character.'),
);

export const isTrue = (message: string) => (value) => value === true ? undefined : message;

export const required = (message: string) => (value: string) => value ? undefined : message;

export const validDateFormat = (message: string) => (value: string) => parseDate(value) ? undefined : message;

export const validDateObject = (message: string) => (value: DateMap) => parseDateObject(value) ? undefined : message;

export const isNumericOrDash = (value: string) => {
  return isNumeric(value) || value == '-';
};

export const isSpaceOrDash = (char: string) => {
  return char == ' ' || char == '-';
};

export const onlyNumbersAndDashes = (message: string) => (value: string) => {
  if (value.split('').some((ch) => !isNumericOrDash(ch))) {
    return message;
  }
  return undefined;
};

export const fieldsMatch = (otherFieldName: string, message: string) => (value: string, values: any) => {
  if (value === values[otherFieldName]) {
    return undefined;
  }

  return message;
};

export const valueMatches = (expectedValue: string, message: string) => (value: string) =>
  value === expectedValue ? undefined : message;

export const onlyAlphanumeric = (message: string) => (value: string) =>
  value && isAlphanumeric(value) ? undefined : message;

export const alphanumericOrSpaceOrDashes = (message: string) => (value: string) =>
  value && (isAlphanumeric(value) || value.split('').some((ch) => !isSpaceOrDash(ch))) ? undefined : message;

export const withinRange = (message: string, min: number, max: number) => (value: string) =>
  isLength(value, { min, max }) ? undefined : message;

export const withoutLeadingDigit = (message: string) => (value: string) =>
  isNumeric(value.charAt(0)) ? message : undefined;

export const isPhoneNumber =
  (message: string, { optional }) =>
  (value: string) => {
    if (value === undefined && optional) {
      return undefined;
    }

    if (value !== undefined && isMobilePhone(value, 'en-US')) {
      return undefined;
    }

    return message;
  };
