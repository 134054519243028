import { FlexLayout, Text } from '~/ui';

export const BookingAllDayPassInfoText = () => (
  <FlexLayout flexDirection="row" space={2}>
    <Text color="white-alpha-75" variant="text-s">
      All Day Pass
    </Text>
    <Text color="white" variant="text-s-medium">
      All Day Access
    </Text>
  </FlexLayout>
);
