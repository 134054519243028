import { Box } from '~/ui';

interface MaxWidthWrapperProps {
  maxWidth?: string;
  mb?: number | number[];
  px?: number | number[];
}

export const MaxWidthWrapper: React.FC<MaxWidthWrapperProps> = ({
  children,
  maxWidth = 'page-content-width',
  px = 0,
  mb = 0,
}) => {
  return (
    <Box mb={mb} mx="auto" px={px} sx={{ boxSizing: 'content-box', maxWidth, width: ['auto', 'auto', '100%'] }}>
      {children}
    </Box>
  );
};
