import { useEffect, useState } from 'react';

export const ClientSideOnly = ({ children }) => {
  const [serverSide, setServerSide] = useState(true);
  useEffect(() => {
    setServerSide(false);
  }, []);

  if (serverSide) {
    return null;
  }

  return children;
};
