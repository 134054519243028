import { format } from 'date-fns';
import phoneParser from 'phone';

import { Auth0SignupArgs } from '~/apis/auth/Auth0SignupArgs';
import { backend } from '~/services/backendService';
import { reportError } from '~/services/logging';
import { errorContains } from '~/utils/error-formats';

export interface SignupArgs {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  zipCode: string;
  phoneNumber?: string;
  dateOfBirth?: Date;
  captcha?: string;
  marketingOptIn: boolean;
}

interface SignupApiData {
  email: string;
  givenName: string;
  familyName: string;
  password: string;
  displayName: string;
  zipCode: string;
  birthDate: string;
  phoneNumber?: string;
  termsAccepted: boolean;
  marketingOptIn: boolean;
}

// just a temporary measure to avoid bugging user with manual phone number normalizing
// hopefully backend will do this for us so we'll be able to kill this function
async function retrySignupWithStandardizedPhoneNumber(data: SignupApiData, error) {
  if (!errorContains(error, 'invalid phone number format')) {
    reportError(error, { message: 'SignUpError', logOnly: true });
    throw error;
  }
  const { phoneNumber } = data;
  const parsedPhoneNumber = phoneNumber && phoneParser(phoneNumber, 'USA', true)[0];
  if (!parsedPhoneNumber) {
    reportError(error, { message: 'SignUpError', logOnly: true });
    throw error;
  }

  return await backend.post('/auth/signup', {
    ...data,
    phoneNumber: parsedPhoneNumber,
  });
}

// const assertDateOfBirth = nonNullAsserter('dateOfBirth');

export async function signUp(args: SignupArgs) {
  const { username, password, email, dateOfBirth, firstName, lastName, zipCode, phoneNumber, marketingOptIn } = args;
  if (!dateOfBirth) {
    throw new Error('Date of birth cannot be null');
  }

  const data: SignupApiData = {
    birthDate: dateOfBirth && format(dateOfBirth, 'yyyy-MM-dd'),
    displayName: username,
    email,
    familyName: lastName,
    givenName: firstName,
    password,
    phoneNumber,
    zipCode,
    termsAccepted: true,
    marketingOptIn: !!marketingOptIn,
  };

  try {
    return await backend.post('/auth/signup', data);
  } catch (e) {
    if (e.response?.data?.error === 'UsernameExistsException') {
      throw e;
    }

    return retrySignupWithStandardizedPhoneNumber(data, e);
  }
}

export async function auth0RegisterUser(url: string, args: Auth0SignupArgs.RootObject) {
  await backend.post(url, args);
}
