import '@culturehq/add-to-calendar/dist/styles.css';

import AddToCalendar from '@culturehq/add-to-calendar';

import { GetBooking } from '~/apis';
import { arenaQuery } from '~/hooks/api';
import { isEnabledFeature } from '~/services/featureFlags';
import { LinkButton, theme } from '~/ui';
import { getAddress, getName } from '~/utils/booking';

const variantsMap = {
  primary: {
    fill: theme.colors['pink-500'],
    fillHover: theme.colors['pink-600'],
  },
  secondary: {
    fill: theme.colors.white,
    fillHover: theme.colors['gray-200'],
  },
};

interface AddBookingToCalendarButtonProps {
  booking: GetBooking.RootObject;
  variant?: keyof typeof variantsMap;
}

export const AddBookingToCalendarButton: React.FC<AddBookingToCalendarButtonProps> = ({
  booking,
  variant = 'primary',
}) => {
  if (!isEnabledFeature('add-to-calendar-button')) {
    return null;
  }

  const {
    allDayPass,
    seatReservation: { arenaId, fromTime, toTime },
  } = booking;
  const { data: arena } = arenaQuery.use({ slug: arenaId });

  const eventName = arena ? getName({ arena, date: fromTime, isAllDayPass: !!allDayPass }) : '';
  const eventLocation = arena ? getAddress(arena.location) : '';

  return (
    <AddToCalendar
      event={{
        name: eventName,
        details: '',
        location: eventLocation,
        startsAt: fromTime,
        endsAt: toTime,
      }}
      filename={eventName.replaceAll(' ', '_')}
    >
      <LinkButton
        as="div"
        iconLeft="calendar"
        sx={{
          path: {
            fill: variantsMap[variant].fill,
          },
          '&:hover path': {
            fill: variantsMap[variant].fillHover,
          },
        }}
        text="Add to calendar"
        variant={variant}
      />
    </AddToCalendar>
  );
};
