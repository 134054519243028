import Router from 'next/router';
import { useEffect } from 'react';

import { reportError } from '~/services/logging';
import { isServer } from '~/utils/environment';
import { getAnonymousId } from '~/utils/persisted';
import { getNextRoutePath } from '~/utils/routing';

const segmentApiKey = process.env.NEXT_PUBLIC_SEGMENT_API_KEY;
if (!segmentApiKey) {
  throw new Error('Missing NEXT_PUBLIC_SEGMENT_API_KEY');
}

/**
 * Send page visit information to Segment
 */
export function pageTrack() {
  try {
    window?.analytics?.page();
  } catch (e) {
    reportError(e, { message: 'Page track error', logOnly: true });
  }
}

interface ButtonEventTargetProps {
  innerText?: string;
  nodeName?: string;
  parentNode?: any;
  textContent?: string;
  type?: string;
}

function getButtonType(eventTarget: ButtonEventTargetProps) {
  if (!eventTarget) {
    return 'Unknown';
  }
  const { type, nodeName } = eventTarget;
  if (nodeName === 'BUTTON') {
    return type === 'submit' ? 'Submit' : 'Button';
  }

  if (nodeName === 'A') {
    return 'Link';
  }

  return nodeName;
}

function buttonTrack(eventTarget: ButtonEventTargetProps, href: string) {
  const buttonLocation =
    ['Header', 'Footer', 'Nav'].find((element) => eventTarget?.parentNode?.closest(element.toLowerCase())) ?? 'Body';

  const eventName = 'Button Clicked';
  const trackEvent = {
    button_text: eventTarget?.innerText || eventTarget?.textContent,
    button_type: getButtonType(eventTarget),
    destination_url: href,
    button_location: buttonLocation,
  };
  window?.analytics?.track(eventName, trackEvent);
}

/**
 * Identifies user data and sends it to Segment.
 */
export async function userInfoTrack({ id, email, name }) {
  try {
    if (!id) {
      return;
    }

    window?.analytics?.identify(id, {
      name: name,
      email: email,
      application: 'Web',
    });
  } catch (e) {
    reportError(e, { message: 'Identify User Error' });
  }
}

/**
 * Resets user analytics data for Segment tracking.
 */
export async function userInfoReset() {
  window?.analytics?.reset();
}

export interface EventProps {
  email?: string;
}

/**
 * Sends specific event data to Segment.
 */
export async function eventTrack(eventName: string, extraInfo?: EventProps) {
  window?.analytics?.track(eventName, { application: 'Web', ...extraInfo });
}

function findAnchor(element, tags: string[]): any | undefined {
  if (!element) {
    return undefined;
  }

  if (tags.includes(element.nodeName)) {
    return element;
  }

  return findAnchor(element.parentElement, tags);
}

async function listener(e) {
  const el = findAnchor(e.target, ['BUTTON', 'A']);

  if (el?.nodeName === 'BUTTON') {
    const parentElement = el.parentElement;
    const href = parentElement?.href ?? '';

    if (href === '') {
      const nextRoutePath = await getNextRoutePath();
      const baseUrl = e.view.location.origin;
      const destinationUrl = baseUrl + nextRoutePath;

      buttonTrack(el, destinationUrl);
    } else {
      buttonTrack(el, href);
    }
  }

  if (el?.nodeName === 'A') {
    buttonTrack(el, el.href);
  }
}

export function setupEventListeners() {
  if (isServer()) {
    return;
  }

  document.body.addEventListener('click', listener, { capture: true });

  return () => document.body.removeEventListener('click', listener);
}

/** On each route change track page analytics */
Router.events.on('routeChangeComplete', () => {
  pageTrack();
});

export function useSegmentAnonymousId() {
  useEffect(() => {
    if (isServer()) {
      return;
    }
    try {
      window?.analytics?.setAnonymousId(getAnonymousId());
    } catch (e) {
      // just ignore anonymous user id in case segment does not exist
    }
  }, []);
}

export function getSegmentAnonymousId() {
  try {
    return window?.analytics?.user().anonymousId();
  } catch {
    return undefined;
  }
}

export function getSegmentUserId() {
  try {
    return window?.analytics?.user().id();
  } catch {
    return undefined;
  }
}

export { segmentApiKey };
