import { useState } from 'react';

import { Button, FlexLayout, Modal, Text } from '~/ui';

interface UseConfirmModalArgs {
  title?: string;
  content: JSX.Element | string;
  actionButton: {
    text: string;
    isDisabled?: boolean;
    handler(): Promise<any>;
    errorHandler?(error: any): void;
  };
  cancelButtonText?: string;
}

export const useConfirmModal = ({
  actionButton,
  content,
  cancelButtonText = 'Cancel',
  title,
}: UseConfirmModalArgs): [JSX.Element, () => void] => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function showModal() {
    setIsVisible(true);
  }

  function hideModal() {
    setIsVisible(false);
  }

  async function handleActionClick() {
    setIsLoading(true);
    actionButton
      .handler()
      .catch(actionButton.errorHandler)
      .finally(() => {
        setIsLoading(false);
        hideModal();
      });
  }

  const modal = (
    <Modal bg="gray-900" isVisible={isVisible} shouldDisableAutoHide={isLoading} onHide={hideModal}>
      <FlexLayout flexDirection="column" space={6}>
        <FlexLayout flexDirection="column" space={5}>
          {title && (
            <Text color="white" variant="title-m">
              {title}
            </Text>
          )}
          <Text color="white-alpha-75" variant="text-m">
            {content}
          </Text>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout mt={6} space={2}>
        <Button
          data-testid="action-button"
          isDisabled={actionButton.isDisabled}
          isLoading={isLoading}
          text={actionButton.text}
          variant="secondary"
          onClick={handleActionClick}
        />
        <Button
          data-testid="cancel-action-button"
          isDisabled={isLoading}
          text={cancelButtonText}
          variant="ghost"
          onClick={hideModal}
        />
      </FlexLayout>
    </Modal>
  );

  return [modal, showModal];
};
