import { Text } from '~/ui';
import { TextVariant } from '~/ui/theme';

const Placeholder = ({ variant }: { variant: TextVariant }) => {
  return (
    <Text color="white" variant={variant}>
      unknown
      <Text color="white-alpha-50" variant={variant}>
        #000
      </Text>
    </Text>
  );
};

interface UsernameProps {
  user:
    | {
        'custom:displayName'?: string;
        displayName?: string;
        email?: string;
      }
    | undefined;
  variant?: TextVariant;
}

export const Username: React.FC<UsernameProps> = ({ user, variant = 'text-m-medium' }) => {
  if (!user) {
    return <Placeholder variant={variant} />;
  }

  if (user['custom:displayName'] || user.displayName) {
    const displayName = user.displayName ?? user['custom:displayName'];

    if (!displayName) {
      return <Placeholder variant={variant} />;
    }

    const splitUsername = displayName.split('#');
    const username = splitUsername[0];
    const hashNumber = `#${splitUsername[1]}`;

    return (
      <Text color="white" variant={variant}>
        {username}
        <Text color="white-alpha-50" variant={variant}>
          {hashNumber}
        </Text>
      </Text>
    );
  }

  if (user.email) {
    return (
      <Text color="white" variant={variant}>
        {user.email}
      </Text>
    );
  }

  return <Placeholder variant={variant} />;
};
