import keyBy from 'lodash/keyBy';
import { useRef } from 'react';
import Select, { OptionTypeBase } from 'react-select';
import { Dictionary } from 'ts-essentials';

import { Box, theme } from '~/ui';
import { hashString } from '~/utils/string';

import * as components from './components';
import stylesFactory from './stylesFactory';

export interface Option {
  helper?: string;
  iconLeft?: theme.Icon;
  iconRight?: theme.Icon;
  isDisabled?: boolean;
  label: string;
  value: string | number;
  dropdownItemLabel?: string;
}

export interface SingleSelectProps {
  iconLeft?: theme.Icon;
  isDisabled?: boolean;
  isSearchable?: boolean;
  name?: string;
  options: Option[];
  placeholder?: string;
  value: OptionTypeBase | null | string | number | undefined;
  onChange: (string) => void;
  'data-testid'?: string;
}

function getSelectValue(value, optionsMap: Dictionary<Option>): Option | null {
  return optionsMap[value] || null;
}

export const SingleSelect: React.FC<SingleSelectProps> = (props) => {
  const {
    iconLeft,
    isDisabled = false,
    isSearchable = false,
    name,
    options,
    placeholder = 'Select',
    value,
    onChange,
    'data-testid': dataTestId,
  } = props;

  const optionsMap = keyBy(options, (option) => option.value);
  const ref = useRef<string>();
  if (!ref.current) {
    ref.current = hashString(JSON.stringify(props));
  }

  return (
    <Box data-testid={dataTestId}>
      <Select
        components={components}
        iconLeft={iconLeft}
        instanceId={ref.current} // some error related to Next.js (https://github.com/JedWatson/react-select/issues/2629)
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        name={name}
        options={options}
        placeholder={placeholder}
        styles={stylesFactory()}
        value={getSelectValue(value, optionsMap)}
        onChange={(updatedValue) => {
          onChange(updatedValue ? updatedValue.value : null);
        }}
      />
    </Box>
  );
};
