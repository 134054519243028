import { Box, FlexLayout, Icon, styles, theme } from '~/ui';

const paddingMap = {
  both: ['52px', '52px'],
  left: ['52px', '4'],
  right: ['4', '52px'],
  default: ['4', '4'],
};

export interface TextInputProps {
  autoComplete?: string;
  name?: string;
  iconLeft?: theme.Icon;
  iconRight?: theme.Icon;
  isDisabled?: boolean;
  maxLength?: number;
  pattern?: string;
  placeholder?: string;
  type?: 'text' | 'password';
  onFocus?(): void;
  onBlur?(): void;
  value: string;
  onChange: (value: string) => void;
  onClickRightIcon?: () => void;
  id?: string;
}

export const TextInput: React.FC<TextInputProps> = ({
  iconLeft,
  iconRight,
  isDisabled = false,
  maxLength,
  placeholder,
  type = 'text',
  value,
  onChange,
  onClickRightIcon,
  ...rest
}) => {
  let px = paddingMap.default;

  if (iconLeft && iconRight) {
    px = paddingMap['both'];
  } else if (iconLeft) {
    px = paddingMap['left'];
  } else if (iconRight) {
    px = paddingMap['right'];
  }

  const [pl, pr] = px;
  return (
    <FlexLayout
      alignItems="center"
      bg="gray-800"
      isDisabled={isDisabled}
      sx={{
        borderRadius: 's',
        height: 'input-l-height',
        position: 'relative',
        '&:hover': {
          backgroundColor: 'gray-700',
        },
        '&:focus-within': {
          backgroundColor: 'gray-600',
        },
      }}
    >
      {iconLeft && (
        <Icon
          data-testid="ui/textInput-iconLeft"
          icon={iconLeft}
          sx={{ position: 'absolute', top: 4, bottom: 4, left: 4, zIndex: 1 }}
        />
      )}
      <Box
        as="input"
        bg="transparent"
        color="white"
        isDisabled={isDisabled}
        maxLength={maxLength}
        pl={pl}
        placeholder={placeholder}
        pr={pr}
        py={0}
        sx={{
          ...styles.interactions.clickable,
          border: 'none',
          caretColor: 'white',
          height: '100%',
          outline: 'none',
          variant: 'text.text-m',
          width: '100%',
          zIndex: 2,
          '::placeholder': {
            color: 'white-alpha-50',
          },
          ':-webkit-autofill': {
            WebkitTextFillColor: 'white',
            WebkitBoxShadow: '0 0 0 1000px #00000000 inset',
            transition: 'background-color 5000s ease-in-out 0s',
          } as any,
          '&[disabled]': { opacity: 'revert' },
        }}
        type={type}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        {...rest}
      />
      {iconRight && (
        <Icon
          data-testid="ui/textInput-iconRight"
          icon={iconRight}
          sx={{ position: 'absolute', top: 4, bottom: 4, right: 4, zIndex: 2 }}
          onClick={onClickRightIcon}
        />
      )}
    </FlexLayout>
  );
};
