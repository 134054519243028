import { Textarea as ThemeTextarea } from 'theme-ui';

interface Textarea {
  isDisabled?: boolean;
  placeholder?: string;
  rows?: number;
  value: string | undefined;
  onChange(value: string): void;
}

export const Textarea: React.FC<Textarea> = ({
  isDisabled = false,
  placeholder = 'Type something...',
  rows = 4,
  value,
  onChange,
}) => {
  return (
    <ThemeTextarea
      bg="gray-800"
      disabled={isDisabled}
      opacity={isDisabled ? 0.5 : 1}
      p={4}
      placeholder={placeholder}
      rows={rows}
      sx={{
        border: 'none',
        borderRadius: 's',
        outline: 'none',
        resize: 'none',
        '::placeholder': {
          color: 'white-alpha-50',
        },
      }}
      value={value}
      variant="textarea.primary"
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
    />
  );
};
