import * as fonts from './';

export const fontFaces = `
      @font-face {
        font-family: 'Neusa Next Pro Compact';
        src: url(${fonts.CompactMediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Neusa Next Pro';
        src: url(${fonts.RegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Neusa Next Pro';
        src: url(${fonts.BoldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Neusa Next Pro';
        src: url(${fonts.MediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
    `;
