import { Link } from '~/components/shared';
import { Box, GridLayout, Text } from '~/ui';

import { useFooterLinks } from '../Navbar/hooks';
import { LinksContainer } from './LinksContainer';
import { SocialsContainer } from './SocialsContainer';

export const Footer = () => {
  const footerLinks = useFooterLinks();

  return (
    <Box as="footer" bg="black" px={5} py={[7, 8]}>
      <GridLayout gap={7} sx={{ maxWidth: ['375px', '865px'], mx: 'auto' }}>
        <GridLayout gap={6} gridTemplateColumns={['1fr', 'repeat(2, 1fr)']}>
          <GridLayout gap={[6, 7]} sx={{ order: [2, 1] }}>
            <SocialsContainer />
            <Text color="white-alpha-75" variant="display-paragraph-m">
              © 2021. Belong Gaming, LLC 2021. <br />
              Belong is a trademark of Belong Gaming, LLC. <br />
              All rights reserved. <br /> <br />
              <Link href="https://vindex.gg" sx={{ color: 'white-alpha-75' }} target="_blank">
                A Vindex Company.
              </Link>
            </Text>
          </GridLayout>
          <GridLayout columnGap={5} gridTemplateColumns="repeat(2, 1fr)" rowGap={[5, 5, 6]} sx={{ order: [1, 2] }}>
            {footerLinks && (
              <>
                <LinksContainer items={footerLinks.resourceItems} title="Resources" />
                <LinksContainer items={footerLinks.aboutItems} title="About" />
              </>
            )}
          </GridLayout>
        </GridLayout>
      </GridLayout>
    </Box>
  );
};
