import { Text, theme } from '~/ui';

interface VariantMapInterface {
  [key: string]: {
    bg: theme.Color;
    color: theme.Color;
  };
}

const variantsMap: VariantMapInterface = {
  primary: {
    bg: 'white',
    color: 'black',
  },
  ghost: {
    bg: 'white-alpha-25',
    color: 'white',
  },
  red: {
    bg: 'red-500',
    color: 'white',
  },
  yellow: {
    bg: 'yellow-500',
    color: 'black',
  },
  green: {
    bg: 'green-500',
    color: 'black',
  },
  pink: {
    bg: 'pink-500',
    color: 'white',
  },
};

interface PillProps {
  text: string;
  variant?: keyof typeof variantsMap;
}

export const Pill: React.FC<PillProps> = ({ text, variant = 'primary' }) => {
  const { bg, color } = variantsMap[variant];

  return (
    <Text color={color} sx={{ bg, borderRadius: 's', px: 2, py: 1, width: 'fit-content' }} variant="text-s-medium">
      {text}
    </Text>
  );
};

export const pillVariantsMap = variantsMap;
