import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { UpdateUserArgs } from '~/apis';
import { updateUserViaAuth0 } from '~/apis/auth/updateUserViaAuth0';
import { auth0EnvValues } from '~/components/Auth0Provider';
import { userInfoQuery } from '~/hooks/api/auth0';
import { eventTrack, userInfoReset } from '~/services/analytics';
import { delay } from '~/utils/async';
import { auth0UserToCognitoIdToken } from '~/utils/auth0';

interface UseAuthenticatedUserArgs {
  unauthorizedRedirect: boolean;
  path?: string;
}

function useAuth0User() {
  const { isLoading: auth0Loading, user: auth0User } = useAuth0();
  const userId = auth0User?.sub;

  const { isLoading, error, data: user } = userInfoQuery.use(userId && { userId: encodeURIComponent(userId) });

  return { isLoading: auth0Loading || isLoading, data: { user: user && auth0UserToCognitoIdToken(user) }, error };
}

// hooks for api calls can use this hook to safely fetch fresh access token
export function useAuthenticatedUser(args: UseAuthenticatedUserArgs = { unauthorizedRedirect: false }) {
  const { replace } = useRouter();
  const { unauthorizedRedirect, path } = args;

  const { isLoading, data, error } = useAuth0User();

  // redirect if guard is enabled
  useEffect(() => {
    if (!isLoading && !data?.user && unauthorizedRedirect) {
      replace(path ?? '/login');
    }
  }, [isLoading, data, unauthorizedRedirect, path]);

  return { isLoading, user: data?.user, error };
}

export function useLogout() {
  const { push } = useRouter();
  const queryClient = useQueryClient();
  const logoutImpl = useAuth0().logout;

  return {
    async logout() {
      const redirectPromise = push('/');
      await logoutImpl({ returnTo: auth0EnvValues.redirectUri });
      await queryClient.invalidateQueries('useAuthenticatedUser');
      userInfoReset();
      eventTrack('Logout Succeeded');
      await redirectPromise;
      delay(1000).then(() => queryClient.invalidateQueries());
    },
  };
}

export function useUpdateUser() {
  const queryClient = useQueryClient();
  const { user } = useAuthenticatedUser();

  async function clearCache() {
    await queryClient.invalidateQueries('useAuthenticatedUser');
    await userInfoQuery.clear(queryClient);
  }

  return {
    async updateUser(args: UpdateUserArgs) {
      if (!user) {
        return;
      }

      const address = args['custom:zip']
        ? {
            postalCode: args['custom:zip'],
          }
        : undefined;

      await updateUserViaAuth0(user.sub, {
        givenName: args.name,
        familyName: args.family_name,
        username: args.username,
        email: args.email,
        userMetadata: {
          phoneNumber: args.phone_number,
          address,
          marketingOptIn: args['custom:marketingOptIn'] === 'true',
        },
      });
      await clearCache();
    },
  };
}
