import { AxiosError } from 'axios';

import {
  CreateMembershipArgs,
  GetMembershipCancelReasons,
  GetMemberships,
  UpdateMembershipAutoRenewArgs,
  UpdateMembershipPayment,
  UpdateMembershipPaymentArgs,
} from '~/apis';
import { existingPaymentMethodsQuery } from '~/hooks/api/payments';
import { backend } from '~/services/backendService';
import { mutation, NoArgs, query } from '~/utils/apiHooks';
import { eventually } from '~/utils/async';

export const membershipsQuery = query<{ params: { userId: string } }, GetMemberships.RootObject>(
  async (url, config) => {
    try {
      return await backend.get(url, config);
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status === 404) {
        return undefined;
      }
      throw e;
    }
  },
  '/memberships',
);

export const membershipCancelReasonsQuery = query<NoArgs, GetMembershipCancelReasons.RootObject>(
  backend.get,
  '/memberships/cancel-reasons',
);

export const addMembershipCancelReasonMutation = mutation<
  { membershipId: string; cancelReasonId: number },
  GetMemberships.Item
>(backend.put, '/memberships/:membershipId/cancel-reasons/:cancelReasonId', (client) => membershipsQuery.clear(client));

export const updateMembershipPaymentMethodMutation = mutation<
  UpdateMembershipPaymentArgs,
  UpdateMembershipPayment.RootObject
>(
  (url: string, { payment }: UpdateMembershipPaymentArgs) => {
    return backend.put(url, { payment });
  },
  '/memberships/:membershipId/payment-card',
  (client) => membershipsQuery.clear(client),
);

export const updateMembershipAutoRenewMutation = mutation<UpdateMembershipAutoRenewArgs, GetMemberships.Item>(
  (url: string, { autoRenew }: UpdateMembershipAutoRenewArgs) => {
    return backend.put(url, { autoRenew });
  },
  '/memberships/:membershipId/auto-renew',
  (client, { membershipId }, result) => {
    membershipsQuery.setData(client, { params: { userId: result.userId } }, (data) => {
      data.items.map((item) => {
        if (item.id === membershipId) {
          item.autoRenew = result.autoRenew;
        }
      });
    });

    eventually(() => {
      membershipsQuery.clear(client);
    });
  },
);

export const createMembershipMutation = mutation<CreateMembershipArgs.RootObject, any>(
  backend.post,
  '/memberships',
  (client) =>
    eventually(() => {
      membershipsQuery.clear(client);
      existingPaymentMethodsQuery.clear(client);
    }),
);
