import { Form, validators } from '~/components/form';
import { EmailAddressFormField } from '~/components/form/EmailAddressFormField';
import { useAuthenticatedUser } from '~/hooks/api';
import { useStatefulRouter } from '~/hooks/statefulRouter';
import { Box, FlexLayout, Image, Only, Text } from '~/ui';

interface SignupBannerProps {
  data: {
    body: string;
    title: string;
    image: { url: string };
  };
}

export const SignupBanner: React.FC<SignupBannerProps> = ({ data }) => {
  const { push, pushWithState } = useStatefulRouter();
  const { user } = useAuthenticatedUser();

  if (user) {
    return null;
  }

  if (!data) {
    return null;
  }

  async function handleSubmit({ email }) {
    if (!user) {
      return pushWithState('/signup', { email });
    }

    return push('/');
  }

  const {
    body,
    image: { url: imageUrl },
    title,
  } = data;
  return (
    <Box bg="black" sx={{ position: 'relative', mb: 8 }}>
      <Only for="desktop">
        <Image
          src={imageUrl}
          sx={{
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        />
      </Only>
      <FlexLayout
        flexDirection="column"
        p={[4, 5, 5]}
        space={5}
        sx={{ maxWidth: ['100%', '100%', '480px'], position: 'relative' }}
      >
        <Text variant="display-heading-m">{title}</Text>
        <Text variant="display-paragraph-l">{body}</Text>
        <Form
          render={({ handleSubmit }) => (
            <EmailAddressFormField
              label="Email address"
              name="email"
              validate={validators.email('Please use the correct email address format.')}
              onSubmit={handleSubmit}
            />
          )}
          onSubmit={handleSubmit}
        />
      </FlexLayout>
    </Box>
  );
};
