export const fonts = {
  body: 'Neusa Next Pro',
  compact: 'Neusa Next Pro Compact',
};

export const fontStyles = {
  normal: 'normal',
};

export const fontWeights = {
  normal: 'normal',
  medium: '500',
  bold: '700',
};
