import { ExistingPaymentMethod } from '~/pages-components/AccountPage/Payment/Card';
import * as icons from '~/ui/assets/icons';

import { typedObjectKeys } from './object';

export const getCardTypeIconName = (cardBrand: string | undefined) => {
  if (!cardBrand) {
    return 'creditCard';
  }

  const icon = typedObjectKeys(icons).find((iconName) =>
    iconName.toLowerCase().endsWith(cardBrand.toLowerCase().replace('_', '').replace(' ', '')),
  );

  return icon ?? 'creditCard';
};

export function formatPriceObject(price: { currency: string; amount: number }, { isMinorUnit = false } = {}) {
  // e.g. amount is in cents => `isMinorUnit` should be set to `true`
  const divideFactor = isMinorUnit ? 100 : 1;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: price.currency,
    minimumFractionDigits: 2,
  }).format(price.amount / divideFactor);
}

interface CardData {
  expMonth: number;
  expYear: number;
  lastFour: string;
  cardBrand: string;
  cardId: string;
}

export function isSameCard(paymentMethodCard: ExistingPaymentMethod, membershipPaymentMethodCard: CardData) {
  return paymentMethodCard.cardId === membershipPaymentMethodCard.cardId;
}
