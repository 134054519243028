import { useEffect } from 'react';

import { CenteredLoadingSpinner } from '~/components/shared/CenteredLoadingSpinner';

export const Execute = ({ onMount }) => {
  useEffect(() => {
    onMount();
  }, []);

  return <CenteredLoadingSpinner />;
};
