import { Box } from '~/ui';

import { Link, LinkProps } from './Link';

export type OptionalLinkProps =
  | LinkProps
  | {
      href?: string;
    };

export const OptionalLink: React.FC<OptionalLinkProps> = ({ href, ...rest }) => {
  if (href) {
    return <Link href={href} {...rest} />;
  }

  return <Box {...rest} />;
};
