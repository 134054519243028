import { forwardRef } from 'react';

import { Box, BoxProps, FlexLayout, Icon, LoadingSpinner, styles, Text, theme } from '~/ui';

const sizesMap = {
  m: {
    height: 'input-m-height',
    px: 4,
    space: 2,
    textVariant: 'compact-text-l' as theme.TextVariant,
  },
  l: {
    height: 'input-l-height',
    px: 5,
    space: 3,
    textVariant: 'compact-text-xl' as theme.TextVariant,
  },
};

const variantsMap = {
  primary: {
    bg: 'pink-500',
    color: 'white' as theme.Color,
    activeStyles: { bg: 'pink-700' },
    hoverStyles: { bg: 'pink-600' },
  },
  optic: {
    bg: 'green-400',
    color: 'black' as theme.Color,
    activeStyles: { bg: 'green-500' },
    hoverStyles: { bg: 'green-500' },
  },
  secondary: {
    bg: 'white',
    color: 'black' as theme.Color,
    activeStyles: { bg: 'gray-300' },
    hoverStyles: { bg: 'gray-200' },
  },
  ghost: {
    bg: 'transparent',
    color: 'white' as theme.Color,
    activeStyles: { border: 'border-white25' },
    hoverStyles: { border: 'border-white10' },
  },
  brand: {
    bg: 'brandPrimaryColor',
    color: 'brandTextColor' as theme.Color,
    activeStyles: {
      background: (theme) =>
        `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${theme.colors.brandPrimaryColor}`,
    },
    hoverStyles: {
      background: (theme) =>
        `linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), ${theme.colors.brandPrimaryColor}`,
    },
  },
};

export interface ButtonProps extends BoxProps {
  iconLeft?: theme.Icon;
  iconRight?: theme.Icon;
  isFullWidth?: boolean;
  isLoading?: boolean;
  size?: keyof typeof sizesMap;
  text: string;
  variant?: keyof typeof variantsMap;
}

export const Button = forwardRef<any, ButtonProps>(
  (
    {
      iconLeft,
      iconRight,
      isDisabled = false,
      isFullWidth = false,
      isLoading = false,
      size = 'm',
      text,
      variant = 'primary',
      onClick,
      as = 'button',
      ...rest
    },
    ref,
  ) => {
    const { height, px, space, textVariant } = sizesMap[size];
    const { bg, color, activeStyles, hoverStyles } = variantsMap[variant];

    return (
      <Box
        as={as}
        isDisabled={isDisabled || isLoading}
        ref={ref}
        sx={{
          ...styles.interactions.clickable,
          bg,
          border: '2px solid transparent',
          borderRadius: 's',
          color,
          height,
          position: 'relative',
          px,
          width: isFullWidth ? '100%' : 'fit-content',
          display: as === 'a' ? 'flex' : 'initial',
          justifyContent: as === 'a' ? 'center' : 'initial',
          '&:hover': hoverStyles,
          '&:active': activeStyles,
          flexShrink: 0,
        }}
        onClick={onClick}
        {...rest}
      >
        <FlexLayout
          alignItems="center"
          justifyContent={isFullWidth ? 'center' : 'inherit'}
          space={space}
          sx={{ visibility: isLoading ? 'hidden' : 'visible' }}
        >
          {iconLeft && <Icon icon={iconLeft} />}
          <Text variant={textVariant}>{text}</Text>
          {iconRight && <Icon icon={iconRight} />}
        </FlexLayout>
        {isLoading && (
          <FlexLayout
            alignItems="center"
            justifyContent="center"
            sx={{ bottom: 0, left: 0, position: 'absolute', right: 0, top: 0 }}
          >
            <LoadingSpinner color={color} size={size} />
          </FlexLayout>
        )}
      </Box>
    );
  },
);

export const buttonSizesMap = sizesMap;
export const buttonVariantsMap = variantsMap;
