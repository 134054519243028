import { Box, FlexLayout, Icon, Only, Text, theme, useIsMenuOpen, useScreenType } from '~/ui';

import { useHeaderLinks } from './hooks';
import { NavbarItem } from './NavbarItem';

export const NavbarList = () => {
  const headerLinks = useHeaderLinks();
  const { isTablet, isDesktop } = useScreenType();
  const [isMenuOpen, setIsMenuOpen] = useIsMenuOpen();

  const moreNavItems: React.ReactElement[] = [];
  const navItemColor = isMenuOpen ? 'gray-300' : 'white';

  return (
    <>
      {headerLinks?.map(({ path }, i) => {
        const navItem = <NavbarItem href={path.href} key={path.title} text={path.title} />;

        if ((isTablet && i > 2) || (isDesktop && i > 4)) {
          moreNavItems.push(navItem);
          return null;
        }

        return navItem;
      })}

      {moreNavItems.length > 0 && (
        <Only for="tabletAndDesktop">
          <Box onOutsideClick={() => setIsMenuOpen(false)}>
            <FlexLayout space={3} onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <Text color={navItemColor} variant="compact-text-l">
                More
              </Text>
              <Icon color={navItemColor} icon={isMenuOpen ? 'chevronUp' : 'chevronDown'} />
            </FlexLayout>
            {isMenuOpen && (
              <FlexLayout
                bg="black"
                flexDirection="column"
                ml={-6}
                mt={2}
                p={6}
                space={6}
                sx={{
                  width: '343px',
                  position: 'absolute',
                  border: `1px solid ${theme.colors['gray-800']}`,
                }}
              >
                {moreNavItems}
              </FlexLayout>
            )}
          </Box>
        </Only>
      )}
    </>
  );
};
