import { useRouter } from 'next/router';

import { GetBooking } from '~/apis';
import { acceptInviteMutation, declineInviteMutation } from '~/hooks/api';
import { reportError } from '~/services/logging';
import { Button, FlexLayout, showToast, useConfirmModal } from '~/ui';
import { displayError } from '~/utils/errors';

import { Username } from './Username';

interface BookingInviteActionsProps {
  bookingId: string;
  owner: GetBooking.UserInfo;
}

export const BookingInviteActions: React.FC<BookingInviteActionsProps> = ({ bookingId, owner }) => {
  const { push } = useRouter();
  const { isLoading: isLoadingAccept, mutateAsync: acceptInvite } = acceptInviteMutation.use();
  const { mutateAsync: declineInvite } = declineInviteMutation.use();

  const [modal, showModal] = useConfirmModal({
    actionButton: {
      text: 'Decline',
      handler: handleDeclineInvite,
      errorHandler: displayError,
    },
    title: 'Decline invitation',
    content: (
      <>
        Are you sure you want to decline the invitation from <Username user={owner} variant="text-m" />?
      </>
    ),
  });

  async function handleAcceptInvite() {
    try {
      await acceptInvite({
        booking: {
          id: bookingId,
        },
      });
      showToast({
        text: 'Invitation accepted!',
        variant: 'success',
      });
    } catch (error) {
      reportError(error, { message: 'BookingInviteActionsError', logOnly: true });
      displayError(error);
    }
  }

  async function handleDeclineInvite() {
    await declineInvite({
      booking: {
        id: bookingId,
      },
    });
    showToast({
      text: 'You have declined an invitation.',
      variant: 'success',
    });
    push('/account/bookings');
  }

  return (
    <>
      <FlexLayout flexDirection="row" space={4}>
        <Button
          iconLeft="checkmark"
          isLoading={isLoadingAccept}
          text="Accept"
          variant="primary"
          onClick={handleAcceptInvite}
        />
        <Button iconLeft="close" text="Decline" variant="ghost" onClick={showModal} />
      </FlexLayout>
      {modal}
    </>
  );
};
