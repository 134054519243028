import { Link, TwitterTimeline } from '~/components/shared';
import { isEnabledFeature } from '~/services/featureFlags';
import { Box, FlexLayout, Image, Only, Text, useScreenType } from '~/ui';

export function MaintenancePage({ children }) {
  if (!isEnabledFeature('maintenance-mode')) {
    return children;
  }

  const { isMobile } = useScreenType();

  const content = (
    <FlexLayout
      flexDirection="column"
      mr={5}
      mt={[4, 5, 8]}
      px={[4, 5, 0]}
      space={6}
      sx={{ maxWidth: ['343px', '613px'] }}
    >
      <FlexLayout flexDirection="column" space={4}>
        <Text color="white" variant={isMobile ? 'display-heading-m' : 'display-heading-l'}>
          MAINTENANCE
        </Text>
        <Text color="white-alpha-75" variant={isMobile ? 'display-paragraph-m' : 'display-paragraph-l'}>
          Belong Platform is currently down due to maintenance work. We expect to be back as soon as possible. Check
          back soon!
        </Text>
      </FlexLayout>
      <Text color="white-alpha-75" variant={isMobile ? 'display-paragraph-m' : 'display-paragraph-l'}>
        Need help?<Link href="mailto:customerservice@belong.gg"> Contact our support team</Link>.
      </Text>
      <Box sx={{ maxWidth: '341px', overflow: 'hidden' }}>
        <TwitterTimeline username="BelongArenas" />
      </Box>
    </FlexLayout>
  );

  return (
    <Box sx={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}>
      <FlexLayout backgroundColor="black" sx={{ height: 'navbar-height' }}>
        <Image src="/assets/images/belong-logo.svg" />
      </FlexLayout>
      <Only for="desktop">
        <FlexLayout
          flexDirection="row"
          justifyContent="space-around"
          m={8}
          sx={{
            top: 72,
            bottom: 0,
            left: 0,
            right: 0,
            position: 'absolute',
            background: 'url(/assets/images/maintenance-background.jpg)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            flexGrow: 1,
          }}
        >
          <FlexLayout justifyContent="right" sx={{ width: '75%', height: '100%' }}>
            {content}
          </FlexLayout>
        </FlexLayout>
      </Only>
      <Only for="mobileAndTablet">
        <FlexLayout backgroundColor="black" flexDirection="column" mt={[6, 8]} mx={[0, 4]}>
          <FlexLayout
            sx={{
              background: 'url(/assets/images/maintenance-background.jpg)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              height: ['221px', '436px'],
              width: '100%',
            }}
          />
          {content}
        </FlexLayout>
      </Only>
    </Box>
  );
}
