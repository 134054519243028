import { OptionalLink } from '~/components/shared';
import { FlexLayout, Image } from '~/ui';

export const ArenaLogo = ({ heroImageUrl, logoImageUrl, href }) => {
  return (
    <OptionalLink
      href={href}
      sx={{
        backgroundImage: `url('${heroImageUrl}')`,
        backgroundSize: 'cover',
      }}
    >
      <FlexLayout bg="#000000BF" justifyContent="center">
        <Image src={logoImageUrl} sx={{ width: '486px', height: '283px' }} />
      </FlexLayout>
    </OptionalLink>
  );
};
