import { getNewsItem, getNewsItems } from '~/apis';
import {
  FetchNewsItems,
  GetAboutPage,
  GetAccountPage,
  GetArenaContent,
  GetArenaPage,
  GetBookingPage,
  GetContactUsPage,
  GetCovidPage,
  GetFaqPage,
  GetFeaturedNewsItems,
  GetFooterNavigation,
  GetGamesPage,
  GetHeaderNavigation,
  GetHomePage,
  GetMembershipsPage,
  GetPrivacyPolicyPage,
  GetProgramsPage,
  GetTermsOfServicePage,
} from '~/apis/contentStack';
import { fetchContentStackData, fetchContentStackDataWithRefs, fetchEntriesWithRefs } from '~/services/contentStack';
import { NoArgs, query } from '~/utils/apiHooks';

export const newsItemsQuery = query<NoArgs, FetchNewsItems.Entry[][]>(getNewsItems, 'contentStack:news_items', {
  usePathAsUrl: false,
});

export const newsItemQuery = query<string, FetchNewsItems.Entry>(getNewsItem, 'contentStack:news_item', {
  usePathAsUrl: false,
});

export const getHomepageData = async () => fetchContentStackDataWithRefs('home_page');

export const homePageQuery = query<NoArgs, GetHomePage.RootObject[][]>(getHomepageData, 'contentStack:home_page', {
  usePathAsUrl: false,
});

export const getBookingPageQuery = async () => fetchContentStackDataWithRefs('booking_page');

export const bookingPageQuery = query<NoArgs, GetBookingPage.RootObject[][]>(
  getBookingPageQuery,
  'contentStack:booking_page',
  {
    usePathAsUrl: false,
  },
);

export const getAboutPageData = async () => fetchContentStackDataWithRefs('about_page', 'signup_widget');

export const aboutQuery = query<NoArgs, GetAboutPage.RootObject[][]>(getAboutPageData, 'contentStack:about_page', {
  usePathAsUrl: false,
});

export const membershipsPageQuery = query<NoArgs, GetMembershipsPage.RootObject[][]>(
  fetchContentStackData,
  'membership_page',
);

export const getFeaturedNewsItems = async () =>
  fetchContentStackDataWithRefs<GetFeaturedNewsItems.RootObject[][]>('featured_news_items', 'news_items');

export const featuredNewsItemsQuery = query<NoArgs, GetFeaturedNewsItems.RootObject[][]>(
  getFeaturedNewsItems,
  'contentStack:featured_news_items',
  {
    usePathAsUrl: false,
  },
);

export const termsOfServicePageQuery = query<NoArgs, GetTermsOfServicePage.RootObject[][]>(
  fetchContentStackData,
  'terms_of_service_page',
);

export const termsAndConditionsPageQuery = query<NoArgs, GetTermsOfServicePage.RootObject[][]>(
  fetchContentStackData,
  'terms_and_conditions_page',
);

export const privacyPolicyPageQuery = query<NoArgs, GetPrivacyPolicyPage.RootObject[][]>(
  fetchContentStackData,
  'privacy_policy_page',
);

export const faqPageQuery = query<NoArgs, GetFaqPage.RootObject[][]>(fetchContentStackData, 'faq_page');

export const covidPageQuery = query<NoArgs, GetCovidPage.RootObject[][]>(fetchContentStackData, 'covid_page');

export const contactUsPageQuery = query<NoArgs, GetContactUsPage.RootObject[][]>(
  fetchContentStackData,
  'contact_us_page',
);

export const getAccountPageData = async () => fetchContentStackDataWithRefs('account_page', 'belong_legends_widget');

export const accountPageQuery = query<NoArgs, GetAccountPage.RootObject[][]>(
  getAccountPageData,
  'contentStack:account_page',
  {
    usePathAsUrl: false,
  },
);

const getArenaPageData = async () => fetchContentStackDataWithRefs('arena_page', 'belong_legends_widget');
export const arenaPageQuery = query<NoArgs, GetArenaPage.RootObject[][]>(getArenaPageData, 'contentStack:arena_page', {
  usePathAsUrl: false,
});

export const arenaContentQuery = query<{ slug: string }, GetArenaContent.RootObject[][]>(
  (options) => fetchEntriesWithRefs('arena', options, 'arena_map'),
  'contentStack:arena_content',
  {
    usePathAsUrl: false,
  },
);

const getNavbarData = async () => fetchContentStackData('header_navigation');
export const navbarQuery = query<NoArgs, GetHeaderNavigation.RootObject[][]>(
  getNavbarData,
  'contentStack:header_navigation',
  { usePathAsUrl: false },
);

const getFooterData = async () => fetchContentStackData('footer_navigation');
export const footerQuery = query<NoArgs, GetFooterNavigation.RootObject[][]>(
  getFooterData,
  'contentStack:footer_navigation',
  { usePathAsUrl: false },
);

export const competitionProgramsPageQuery = query<NoArgs, GetProgramsPage.RootObject[][]>(
  fetchContentStackData,
  'competition_programs_page',
);

export const gamesPageQuery = query<NoArgs, GetGamesPage.RootObject[][]>(fetchContentStackData, 'games_page');
