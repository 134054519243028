import EventEmitter from 'events';
import Router from 'next/router';

export function getQueryStringParam(context: any, name: string): string {
  const { query } = context;
  const value = query[name];

  if (value && typeof value !== 'string') {
    throw new Error(`Invalid query ${name}: ${value}`);
  }

  return value;
}

const emitter = new EventEmitter();
Router.events.on('routeChangeStart', (url) => emitter.emit('routeChangeStart', url));

export function getNextRoutePath() {
  return new Promise<string>((resolve) => {
    emitter.once('routeChangeStart', (path) => resolve(path));
  });
}
