import { ArenaLocate, ArenaLocateArgs, GetArena, GetArenas, GetPricesSummary } from '~/apis';
import { backend } from '~/services/backendService';
import { NoArgs, query } from '~/utils/apiHooks';

export const arenasQuery = query<NoArgs, GetArenas.GetArenasRootObject>(backend.get, '/arenas');
export const arenaQuery = query<{ slug: string }, GetArena.Arena>(backend.get, '/arenas/:slug');
export const arenaPricingQuery = query<{ arenaId: string }, GetPricesSummary.GetPricesSummaryRootObject>(
  backend.get,
  '/prices/summary?arenaId=:arenaId',
);

export const defaultArenaLocateArgs: ArenaLocateArgs = {
  country: '',
  latitude: 40.76036,
  longitude: -73.97345,
  radius: 50000,
  searchText: '',
  searchType: 'POINT',
};

export const arenasLocateQuery = query<ArenaLocateArgs, ArenaLocate.RootObject>(backend.post, '/arenas/locate');
