import sumBy from 'lodash/sumBy';

import { GetBooking } from '~/apis';
import { formatPriceObject, getCardTypeIconName } from '~/utils/payment';
import { capitalizeWordsWithChar } from '~/utils/string';

import { TextIconBox } from './TextIconBox';

function getPaymentDetails(order?: GetBooking.Order) {
  const tender = order?.tenders?.[0];

  if (!tender) {
    return undefined;
  }

  const { card_details, amount_money: amount } = tender;

  const cardBrand = card_details?.card.card_brand;
  const lastFour = card_details?.card.last_4;

  return { cardBrand, amount, lastFour };
}

function getPaymentInfo(order?: GetBooking.Order) {
  const details = getPaymentDetails(order);
  if (!details) {
    return undefined;
  }

  const { amount, cardBrand, lastFour } = details;

  function checkCardType(cardBrand: string) {
    if (cardBrand.toLowerCase() === 'square gift card') {
      return 'Belong Gift Card';
    }

    return cardBrand;
  }

  return {
    cardIcon: cardBrand && getCardTypeIconName(cardBrand),
    cardSubtext: cardBrand && `${checkCardType(capitalizeWordsWithChar(cardBrand))} ending in ${lastFour}`,
    amount: formatPriceObject(amount, { isMinorUnit: true }),
  };
}

interface PaymentCreditCardInfoProps {
  isMembershipBooking: boolean;
  order?: GetBooking.Order;
  paymentHistory?: GetBooking.PaymentHistory[];
}

export const PaymentCreditCardInfo: React.FC<PaymentCreditCardInfoProps> = ({
  isMembershipBooking = false,
  order,
  paymentHistory,
}) => {
  if (isMembershipBooking) {
    return <TextIconBox icon="creditCard" text="$0.00" />;
  }

  const paymentInfo = getPaymentInfo(order);

  if (!paymentInfo || !paymentHistory) {
    return null;
  }

  const paymentHistorySum = formatPriceObject(
    {
      amount: sumBy(paymentHistory, 'totalPrice.amount'),
      currency: paymentHistory[0].totalPrice.currency,
    },
    { isMinorUnit: true },
  );

  const { cardIcon, cardSubtext } = paymentInfo;
  return <TextIconBox icon={cardIcon || 'creditCard'} subtext={cardSubtext} text={paymentHistorySum} />;
};
