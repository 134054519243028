import { FlexLayout, Text } from '~/ui';

interface WithFieldLabelsInterface {
  error?: string;
  helper?: string;
  label?: string;
  auxLabel?: string;
  onClear?(value?: any): void;
}

export function withFieldLabels<T extends { isDisabled?: boolean }>(WrappedComponent: React.FC<T>) {
  return ({ auxLabel, error, helper, label, onClear, ...restProps }: WithFieldLabelsInterface & T) => {
    const { value } = restProps as any;

    return (
      <FlexLayout flexDirection="column" space={3}>
        {label && (
          <FlexLayout isDisabled={restProps.isDisabled} justifyContent="space-between">
            <Text color="white-alpha-75" variant="label-s">
              {label}
            </Text>
            {auxLabel && (
              <Text color="white-alpha-50" variant="label-s">
                {auxLabel}
              </Text>
            )}
            {onClear && value && (
              <Text color="pink-500" isDisabled={restProps.isDisabled} variant="text-s" onClick={onClear}>
                Clear
              </Text>
            )}
          </FlexLayout>
        )}
        <WrappedComponent {...(restProps as any)} />
        {helper && (
          <Text color="white-alpha-75" isDisabled={restProps.isDisabled} variant="text-s">
            {helper}
          </Text>
        )}
        {error && (
          <Text color="red-500" isDisabled={restProps.isDisabled} variant="text-s">
            {error}
          </Text>
        )}
      </FlexLayout>
    );
  };
}
