import { Link } from '~/components/shared';
import { Button, ButtonProps } from '~/ui';

export interface AnchorButtonProps extends Omit<ButtonProps, 'onClick'> {
  href: string;
}

export const AnchorButton: React.FC<AnchorButtonProps> = ({ href, ref, ...rest }) => {
  return (
    <Link href={href} withoutBox>
      <Button {...rest} as="a" />
    </Link>
  );
};
