import { useCountrySelectionChecker } from '~/components/CountryPickerModal/useCountrySelectionChecker';
import flagUK from '~/public/assets/images/flag-uk.jpg';
import flagUS from '~/public/assets/images/flag-us.jpg';
import { FlexLayout, Modal, Text } from '~/ui';

import { CountryPickerMenuItem } from '../layout/Navbar/CountryPickerMenuItem';

export const CountryPickerModal = () => {
  const { isCountrySelected, markCountrySelected, isReady } = useCountrySelectionChecker();

  return (
    <Modal isVisible={!isCountrySelected && isReady}>
      <FlexLayout alignItems="center" flexDirection="column" space={6}>
        <Text color="white" variant="title-l">
          Choose a country
        </Text>
        <Text color="white" variant="text-m">
          Belong is only available in the United States and the United Kingdom. Where would you like to go?
        </Text>
        <FlexLayout flexDirection={['column', 'row']} space={[6, 5]} sx={{ width: '100%' }}>
          <CountryPickerMenuItem
            backgroundColor="gray-800"
            image={flagUS.src}
            text="United States"
            onClick={() => markCountrySelected('usa')}
          />
          <div>
            <CountryPickerMenuItem
              backgroundColor="gray-800"
              href="https://belong.gg"
              image={flagUK.src}
              text="United Kingdom"
              onClick={() => markCountrySelected('uk')}
            />
          </div>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};
