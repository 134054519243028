import { add, addMonths, format, isPast, parse, startOfTomorrow } from 'date-fns';
import range from 'lodash/range';
import { DateTime, DurationUnit } from 'luxon';
import { useMemo } from 'react';

import { DayOfWeek, splitMilitaryString } from '~/utils/time';

const dateFormat = 'yyyy-MM-dd';

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d as any);
}

export function parseDate(dateString: string) {
  const date = parse(dateString, dateFormat, new Date());

  if (isValidDate(date)) {
    return date;
  }

  return undefined;
}

export interface DateMap {
  month: string;
  day: string;
  year: string;
}

export function dateMapToString(date: DateMap) {
  const { year, day, month } = date;

  return `${year}-${month}-${day}`;
}

export function parseDateObject(dateObject: DateMap) {
  if (!dateObject) {
    return undefined;
  }

  const { year } = dateObject;
  const currentTime = new Date();
  const lowOffset = Number(currentTime.getFullYear() - 100);
  const highOffset = Number(currentTime.getFullYear() + 100);

  if (parseInt(year) >= lowOffset && parseInt(year) <= highOffset) {
    return parseDate(dateMapToString(dateObject));
  } else {
    return undefined;
  }
}

export const getMonthsNames = () =>
  range(0, 12)
    .map((month) => new Date(2020, month))
    .map((date) => format(date, 'LLLL'));

/**
 * Takes Date object and returns next nearest time quarter in military format.
 * @param date
 * @returns {string}
 */
export function getNextTimeQuarter(date = new Date()): string {
  const shiftedDate = add(date, { minutes: 15 });
  const hoursString = shiftedDate.getHours().toString().padStart(2, '0');
  const quarter = (Math.floor(shiftedDate.getMinutes() / 15) * 15) % 60;
  const quarterString = quarter.toString().padStart(2, '0');

  return `${hoursString}${quarterString}`;
}

interface DateTimeDiffArgs {
  end: string;
  start: string;
}

export function getDateTimeDiff({ start, end }: DateTimeDiffArgs, params: DurationUnit | DurationUnit[]) {
  const startTime = DateTime.fromISO(start);
  const endTime = DateTime.fromISO(end);

  return endTime.diff(startTime, params).toObject();
}

export function toDateString(date: Date) {
  return format(date, dateFormat);
}

export function formatDate(date: Date, format = 'yyyy-MM-dd', options: any = undefined): string {
  return DateTime.fromJSDate(date, options).toFormat(format);
}

export function formatISODateTime(dateTime: string, format = 'MMM d y', zone = 'utc'): string {
  return DateTime.fromISO(dateTime, { zone }).toFormat(format);
}

export function getUTCDateString(date: Date, militaryTime: string, zone = 'utc'): string {
  const [hour, minute] = splitMilitaryString(militaryTime);
  const dateIsoString = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
  return DateTime.fromISO(dateIsoString, { zone }).set({ hour, minute }).toString();
}

type DateFormatOrdinal = 'MMMM do' | 'MMMM do y' | 'MMMM do yy';

export function formatISODateTimeOrdinal(date: string, dateFormat: DateFormatOrdinal = 'MMMM do y') {
  return format(new Date(date), dateFormat);
}

export function getTodaysName() {
  return format(new Date(), 'eeee') as DayOfWeek;
}

export function getTomorrowsName() {
  const tomorrow = startOfTomorrow();

  return format(tomorrow, 'eeee') as DayOfWeek;
}

export function getDayNameFromDate(date: string) {
  return format(new Date(date), 'eeee') as DayOfWeek;
}

export function addDaysToDate(days: number, date: string) {
  const initialDate = DateTime.fromJSDate(new Date(date));

  return initialDate.startOf('day').plus({ days }).toJSDate();
}

export function todayString() {
  return formatDate(new Date());
}

export function useDate(dateString: string | undefined) {
  return useMemo(() => (dateString ? new Date(dateString) : undefined), [dateString]);
}

export function isPastDate(date: string) {
  return isPast(new Date(date));
}

export function addMonthsFromToday(numberOfMonths = 1) {
  return addMonths(new Date(), numberOfMonths);
}

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
