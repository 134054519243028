import { showToast } from '~/ui';

export const scrollToElementId = (id: string) => {
  document?.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
};

export const scrollToTop = () => window.scroll({ top: 0, behavior: 'smooth' });

export const copyToClipboard = async (text: string, toastMessage?: string) => {
  try {
    await navigator.clipboard.writeText(text);
    showToast({
      variant: 'success',
      text: toastMessage || 'Copied to clipboard.',
    });
  } catch {
    showToast({
      variant: 'error',
      text: 'Failed to copy to clipboard.',
    });
  }
};
