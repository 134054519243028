import { Link } from '~/components/shared/Link';
import { FlexLayout, Icon, Text } from '~/ui';

export const ArenaWidgetNotice = ({ notice, allowDetail }) => {
  if (allowDetail && !notice) {
    return null;
  }

  const noDetailText = (
    <>
      More information coming soon.{' '}
      <Link color="pink-500" href="/signup">
        Create an account
      </Link>{' '}
      to receive the latest updates!
    </>
  );

  return (
    <FlexLayout space={3}>
      <Icon icon="info" />
      <Text variant="text-m">{allowDetail ? notice : noDetailText}</Text>
    </FlexLayout>
  );
};
