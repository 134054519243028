import { UserInfo } from '~/apis';
import { IdToken } from '~/utils/auth';

// a temporary util function to be used until cognito is fully removed
export function auth0UserToCognitoIdToken(user: UserInfo.RootObject): IdToken {
  return {
    'cognito:username': user.username,
    'custom:termsAccepted': 'true',
    aud: '',
    auth_time: 0,
    event_id: '',
    exp: 0,
    iat: 0,
    iss: '',
    name: user.givenName,
    phone_number: user.userMetadata.phoneNumber,
    token_use: '',
    email: user.email,
    sub: user.userId,
    'custom:marketingOptIn': (user.userMetadata.marketingOptIn ?? false).toString(),
    'custom:displayName': user.username,
    'custom:emailNotifications': 'false',
    'custom:smsNotifications': 'false',
    'custom:zip': user.userMetadata.address?.postalCode ?? '',
    birthdate: user.userMetadata.birthDate,
    email_verified: user.emailVerified,
    family_name: user.familyName,
    phone_number_verified: false,
  };
}
