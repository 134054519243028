import 'react-toastify/dist/ReactToastify.css';

import { Slide, toast, ToastContainer } from 'react-toastify';

import { Alert, AlertProps } from './Alert';

const toastContainerStyles = {
  bottom: 0,
  padding: 0,
  maxWidth: '572px',
  width: '100%',
};

const toastStyles = {
  display: 'block',
  backgroundColor: 'transparent',
  borderRadius: 0,
  boxShadow: 'unset',
  margin: 0,
  marginTop: '4px',
  minHeight: 'unset',
  padding: 0,
};

export const ToastMessageContainer: React.FC = () => {
  return (
    <ToastContainer
      autoClose={3000}
      closeButton={false}
      closeOnClick={false}
      draggable={false}
      hideProgressBar={true}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      position="bottom-center"
      style={toastContainerStyles}
      transition={Slide}
    />
  );
};

interface ShowToastProps extends AlertProps {
  canClose?: boolean;
  autoClose?: number;
}

export function showToast(props: ShowToastProps) {
  const { canClose, autoClose, ...rest } = props;

  toast(({ closeToast }) => <Alert onCloseClick={canClose ? closeToast : undefined} {...rest} />, {
    style: toastStyles,
    autoClose,
  });
}
