import { FlexLayout, Icon, Pill, Text, theme } from '~/ui';

interface AccountMenuItemProps {
  icon: theme.Icon;
  pillText?: string;
  text: string;
  onClick?: () => void;
  'data-testid'?: string;
}

export const AccountMenuItem: React.FC<AccountMenuItemProps> = ({
  icon,
  pillText,
  text,
  onClick,
  'data-testid': dataTestId,
}) => {
  return (
    <FlexLayout
      data-testid={dataTestId}
      px={5}
      py={4}
      space={3}
      sx={{
        color: 'white-alpha-75',
        '&:hover': {
          bg: 'gray-800',
          color: 'white',
        },
        '&:active': {
          bg: 'gray-800',
          color: 'white',
        },
      }}
      onClick={onClick}
    >
      <Icon icon={icon} />
      <Text variant="text-m-medium">{text}</Text>
      {pillText && <Pill text={pillText} />}
    </FlexLayout>
  );
};
