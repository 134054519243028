import { Link } from '~/components/shared';
import { FlexLayout, Text } from '~/ui';

interface LinkItemProps {
  text: string;
  href: string;
}

interface LinksContainerProps {
  items: LinkItemProps[];
  title: string;
}

export const LinksContainer: React.FC<LinksContainerProps> = ({ items, title }) => {
  return (
    <FlexLayout flexDirection="column" flexGrow={1} space={4}>
      <Text color="white-alpha-75" variant="text-s-medium">
        {title}
      </Text>
      {items.map((item) => (
        <Link href={item.href} key={item.text} target="_blank">
          <Text
            sx={{
              width: 'fit-content',
              transition: 'color .12s ease',
              '&:hover': { color: 'pink-600' },
              '&:active': { color: 'pink-700' },
            }}
            variant="compact-text-m"
          >
            {item.text}
          </Text>
        </Link>
      ))}
    </FlexLayout>
  );
};
