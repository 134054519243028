import { components, OptionProps, SingleValueProps } from 'react-select';

import { Box, FlexLayout, Icon, styles, Text, theme } from '~/ui';

import { Option as OptionType } from './SingleSelect';

export const Control = (props) => {
  const { iconLeft, isDisabled } = props.selectProps;
  const optionIcon = props.getValue()[0]?.iconLeft;
  const icon = optionIcon || iconLeft;

  return (
    <components.Control {...props}>
      <FlexLayout
        alignItems="center"
        bg="gray-800"
        flexGrow={1}
        isDisabled={isDisabled}
        justifyContent="space-between"
        p={4}
        space={2}
        sx={{
          ...styles.interactions.clickable,
          border: 'none',
          borderRadius: 's',
          position: 'relative',
          '&:hover': {
            backgroundColor: 'gray-700',
          },
          '&:focus-within': {
            backgroundColor: 'gray-600',
          },
        }}
      >
        {icon && <Icon icon={icon} />}
        {props.children}
      </FlexLayout>
    </components.Control>
  );
};

export const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Icon color="white" icon={props.selectProps.menuIsOpen ? 'chevronUp' : 'chevronDown'} />
  </components.DropdownIndicator>
);

export const IndicatorSeparator = () => {
  return null;
};

export const NoOptionsMessage = (props) => (
  <components.NoOptionsMessage {...props}>
    <Box bg="gray-800" p={4}>
      <Text color="white-alpha-75">{props.children}</Text>
    </Box>
  </components.NoOptionsMessage>
);

export const Option: React.FC<OptionProps<OptionType, false>> = (props) => {
  const { isDisabled, isFocused, children } = props;
  const { helper, iconLeft, iconRight, dropdownItemLabel } = props.data as OptionType;

  return (
    <components.Option {...props}>
      <FlexLayout
        bg={isFocused && !isDisabled ? theme.colors['gray-700'] : theme.colors['gray-800']}
        color={isFocused || isDisabled ? 'white' : 'white-alpha-75'}
        flexDirection="column"
        isDisabled={isDisabled}
        px={4}
        py={3}
        space={3}
        sx={{
          ...styles.interactions.clickable,
          '&[disabled]': {
            opacity: 0.5,
            pointerEvents: 'none',
          },
        }}
      >
        <FlexLayout alignItems="center" justifyContent="space-between">
          <FlexLayout alignItems="center" space={3}>
            {iconLeft && <Icon data-testid="select-option-icon" icon={iconLeft} />}
            <Text>{dropdownItemLabel || children}</Text>
          </FlexLayout>
          {iconRight && <Icon data-testid="select-option-icon" icon={iconRight} />}
        </FlexLayout>
        {helper && <Text variant="text-s">{helper}</Text>}
      </FlexLayout>
    </components.Option>
  );
};

export const Placeholder = (props) => {
  return (
    <components.Placeholder {...props}>
      <Text color="white-alpha-75">{props.children}</Text>
    </components.Placeholder>
  );
};

export const SingleValue: React.FC<SingleValueProps<OptionType>> = (props) => {
  return (
    <components.SingleValue {...props}>
      <Text color="white">{props.children}</Text>
    </components.SingleValue>
  );
};
