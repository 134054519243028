import { addMinutes, format, startOfDay } from 'date-fns';
import range from 'lodash/range';

import { SingleSelect, SingleSelectProps } from '~/ui';

const quartersCountPerDay = 4 * 24;
const today = startOfDay(new Date());

export interface TimeOption {
  value: string;
  label: string;
}

export const defaultTimeOptions: TimeOption[] = range(0, quartersCountPerDay)
  .map((quarterIndex) => addMinutes(today, quarterIndex * 15))
  .map((time) => ({
    value: format(time, 'HHmm'),
    label: format(time, 'h:mm a'),
  }));

interface TimeSelectProps extends Omit<SingleSelectProps, 'options'> {
  timeOptions?: TimeOption[];
  'data-testid'?: string;
}

export const TimeSelect: React.FC<TimeSelectProps> = ({
  isDisabled,
  value,
  onChange,
  timeOptions = defaultTimeOptions,
  'data-testid': dataTestId,
}) => {
  return (
    <SingleSelect
      data-testid={dataTestId}
      isDisabled={isDisabled}
      options={timeOptions}
      value={value}
      onChange={onChange}
    />
  );
};
