import { FieldValidator } from 'final-form';
import { useField } from 'react-final-form';

import { Checkbox } from '~/ui';

export interface FormCheckboxProps extends Omit<React.ComponentProps<typeof Checkbox>, 'value' | 'onChange'> {
  name: string;
  validate?: FieldValidator<any>;
  'data-testid'?: string;
}

export const FormCheckbox: React.FC<FormCheckboxProps> = (props) => {
  const { name, validate, 'data-testid': dataTestId, ...rest } = props;
  const {
    input: { value, onChange },
  } = useField(name, { validate });

  return (
    <Checkbox data-testid={dataTestId} value={value} onChange={(value) => onChange({ target: { value } })} {...rest} />
  );
};
